@import url(https://fonts.googleapis.com/css?family=Roboto:100,300,400,500,700,900&display=swap);
@import url(https://fonts.googleapis.com/css?family=Big+Shoulders+Text&display=swap);
/******************************************
* @DESC - FUNCTION TO SET SCREEN SIZE
* @WIDTH - 1920
* @HEIGHT - 1080
*******************************************/
/********************
    Global Css
********************/
/***********************************
            Global Reset             
***********************************/
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
}

body {
  margin: 0 2.60417vw;
}

.border_line {
  height: 1px;
  width: 100%;
  border-top: 0.5px solid #2c2c2c33;
}

.margin_top {
  display: -webkit-flex;
  display: flex;
  height: 0vw;
  border-bottom: 0.5px solid rgba(0, 0, 0, 0.24);
}

.error {
  font-family: 'Roboto', sans-serif;
  font-size: 0.83333vw;
  font-weight: 500;
  color: #F44336;
}

.button_yellow {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  font-family: 'Roboto', sans-serif;
  font-size: 1.30208vw;
  font-weight: 500;
  color: white;
  background: #FBC02D;
  border: none;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.24), 0px 0px 2px rgba(0, 0, 0, 0.12);
  height: 3.125vw;
  width: 100%;
}

.davis_bg_yellow {
  background: #FBC02D;
}

.button_yellow:hover {
  font-family: 'Roboto', sans-serif;
  font-size: 1.30208vw;
  font-weight: 500;
  color: white;
  background: #FBC02D;
  height: vw(100px);
  width: vw(200px);
  box-shadow: none;
  height: 3.125vw;
  width: 100%;
}

.button_red {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  font-family: 'Roboto', sans-serif;
  font-size: 1.30208vw;
  font-weight: 500;
  color: white;
  border: none;
  background: #F44336;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.24), 0px 0px 2px rgba(0, 0, 0, 0.12);
  border-radius: 0.52083vw;
  height: 3.125vw;
  width: 10.41667vw;
}

.button_red:hover {
  font-family: 'Roboto', sans-serif;
  font-size: 1.30208vw;
  font-weight: 500;
  color: white;
  background: #F44336;
  height: vw(100px);
  width: vw(200px);
  box-shadow: none;
  height: 3.125vw;
  width: 10.41667vw;
}

.button_green {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  font-family: 'Roboto', sans-serif;
  font-size: 1.30208vw;
  font-weight: 500;
  color: white;
  border: none;
  background: #00C950;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.24), 0px 0px 2px rgba(0, 0, 0, 0.12);
  border-radius: 0.52083vw;
  height: 3.125vw;
  width: 10.41667vw;
}

.button_green:hover {
  font-family: 'Roboto', sans-serif;
  font-size: 1.30208vw;
  font-weight: 500;
  color: white;
  background: #00C950;
  height: vw(100px);
  width: vw(200px);
  box-shadow: none;
  height: 3.125vw;
  width: 10.41667vw;
}

.background_grey {
  background: #c4c4c4;
}

.backgroud_green {
  background: #00C950;
}

.background_yellow {
  background: #FBC02D;
}

.background_red {
  background: #F44336;
}

::-webkit-scrollbar {
  width: 0.52083vw;
  cursor: pointer;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
  cursor: pointer;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #F0BA4A;
  border-radius: 10px;
  cursor: pointer;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #F0BA4A;
  cursor: pointer;
}

.cursor-pointer {
  cursor: pointer;
}

.rollOver_btn {
  background-color: #707070;
  border: 1px solid #707070;
  border-radius: 0.26042vw;
  color: #fff;
  padding: 0.41667vw 0.52083vw;
  text-align: center;
  cursor: pointer;
}

.btn_container {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  margin-top: 1.04167vw;
}

.strong_value {
  font-weight: 600;
}

.w_40 {
  width: 40%;
}

.w_50 {
  width: 50%;
}

.w_60 {
  width: 60%;
}

.w_70 {
  width: 70%;
}

.w_80 {
  width: 80%;
}

.w_90 {
  width: 90%;
}

.w_100 {
  width: 100%;
}

.input_component_main_container {
  height: 6.25vw;
  width: 100%;
  margin: auto;
}

.input_component_main_container input {
  display: -webkit-flex;
  display: flex;
  height: 3.125vw;
  -webkit-align-items: center;
          align-items: center;
  font-family: 'Roboto', sans-serif;
  font-size: 1.04167vw;
  font-weight: 300;
  width: 100%;
  outline: none;
}

.input_component_main_container input::-webkit-input-placeholder {
  display: -webkit-flex;
  display: flex;
  height: 3.125vw;
  -webkit-align-items: center;
          align-items: center;
  font-family: 'Roboto', sans-serif;
  font-size: 1.04167vw;
  color: #9FA1A7;
  text-align: center;
  font-weight: 300;
  width: 100%;
  outline: none;
}

.input_component_main_container input:-ms-input-placeholder {
  display: flex;
  height: 3.125vw;
  align-items: center;
  font-family: 'Roboto', sans-serif;
  font-size: 1.04167vw;
  color: #9FA1A7;
  text-align: center;
  font-weight: 300;
  width: 100%;
  outline: none;
}

.input_component_main_container input::placeholder {
  display: -webkit-flex;
  display: flex;
  height: 3.125vw;
  -webkit-align-items: center;
          align-items: center;
  font-family: 'Roboto', sans-serif;
  font-size: 1.04167vw;
  color: #9FA1A7;
  text-align: center;
  font-weight: 300;
  width: 100%;
  outline: none;
}

.input_component_main_container input:focus {
  display: -webkit-flex;
  display: flex;
  height: 3.125vw;
  -webkit-align-items: center;
          align-items: center;
  font-family: 'Roboto', sans-serif;
  font-size: 1.04167vw;
  font-weight: 300;
  width: 100%;
  outline: none;
}

.change_password_form {
  height: 13.02083vw;
  width: 26.04167vw;
  margin: auto;
}

.change_password_form input {
  display: -webkit-flex;
  display: flex;
  height: 3.125vw;
  -webkit-align-items: center;
          align-items: center;
  font-family: 'Roboto', sans-serif;
  font-size: 1.30208vw;
  font-weight: 300;
  width: auto;
  outline: none;
}

.change_password_form input::-webkit-input-placeholder {
  display: -webkit-flex;
  display: flex;
  height: 3.125vw;
  -webkit-align-items: center;
          align-items: center;
  font-family: 'Roboto', sans-serif;
  font-size: 1.30208vw;
  color: #9FA1A7;
  padding-left: 1.30208vw;
  font-weight: 300;
  width: auto;
  outline: none;
}

.change_password_form input:-ms-input-placeholder {
  display: flex;
  height: 3.125vw;
  align-items: center;
  font-family: 'Roboto', sans-serif;
  font-size: 1.30208vw;
  color: #9FA1A7;
  padding-left: 1.30208vw;
  font-weight: 300;
  width: auto;
  outline: none;
}

.change_password_form input::placeholder {
  display: -webkit-flex;
  display: flex;
  height: 3.125vw;
  -webkit-align-items: center;
          align-items: center;
  font-family: 'Roboto', sans-serif;
  font-size: 1.30208vw;
  color: #9FA1A7;
  padding-left: 1.30208vw;
  font-weight: 300;
  width: auto;
  outline: none;
}

.change_password_form input:focus {
  display: -webkit-flex;
  display: flex;
  height: 3.125vw;
  -webkit-align-items: center;
          align-items: center;
  font-family: 'Roboto', sans-serif;
  font-size: 1.30208vw;
  font-weight: 300;
  width: auto;
  outline: none;
}

.textarea_component_main_container textarea {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  font-family: 'Roboto', sans-serif;
  font-size: 1.04167vw;
  height: 100%;
  font-weight: 300;
  width: 100%;
  border: none;
  outline: none;
  border-bottom: 1px solid black;
  resize: none;
}

.textarea_component_main_container textarea::-webkit-input-placeholder {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  font-family: 'Roboto', sans-serif;
  font-size: 1.04167vw;
  font-weight: 300;
  height: 100%;
  width: 100%;
  border: none;
  outline: none;
  color: #c4c4c4;
  resize: none;
}

.textarea_component_main_container textarea:-ms-input-placeholder {
  display: flex;
  align-items: center;
  font-family: 'Roboto', sans-serif;
  font-size: 1.04167vw;
  font-weight: 300;
  height: 100%;
  width: 100%;
  border: none;
  outline: none;
  color: #c4c4c4;
  resize: none;
}

.textarea_component_main_container textarea::placeholder {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  font-family: 'Roboto', sans-serif;
  font-size: 1.04167vw;
  font-weight: 300;
  height: 100%;
  width: 100%;
  border: none;
  outline: none;
  color: #c4c4c4;
  resize: none;
}

.textarea_component_main_container textarea:focus {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  font-family: 'Roboto', sans-serif;
  font-size: 1.04167vw;
  height: 100%;
  font-weight: 300;
  width: 100%;
  border: none;
  outline: none;
  border-bottom: 1px solid black;
  resize: none;
}

.footer_main_container {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  margin-top: 0.52083vw;
}

.footer_logo_container {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  height: 5.20833vw;
  width: 26.04167vw;
}

.footer_logo_container img {
  height: 4.16667vw;
  width: 7.8125vw;
}

.footer_middler_text_container {
  width: 26.04167vw;
  font-family: 'Roboto', sans-serif;
  font-size: 0.9375vw;
  color: black;
  font-weight: 400;
}

.footer_address_container {
  width: 26.04167vw;
  font-family: 'Roboto', sans-serif;
  font-size: 0.9375vw;
  color: black;
  font-weight: 400;
}

.footer_address_container span {
  display: block;
}

.email_verification_main_container {
  display: -webkit-flex;
  display: flex;
  height: 36.45833vw;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
}

.email_verification_main_container .email_verification_label {
  width: 41.66667vw;
  font-family: 'Roboto', sans-serif;
  font-size: 1.30208vw;
  color: #FBC02D;
  font-weight: 400;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  text-align: center;
}

.forget_password_main_container {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  margin: auto;
}

.forget_password_main_container .forget_password_label {
  display: -webkit-flex;
  display: flex;
  height: 10.41667vw;
  width: 26.04167vw;
  margin: auto;
  font-family: 'Roboto', sans-serif;
  font-size: 1.30208vw;
  font-weight: 500;
  -webkit-align-items: flex-end;
          align-items: flex-end;
  -webkit-justify-content: flex-start;
          justify-content: flex-start;
}

.forget_password_main_container .forget_password_form {
  padding-top: 2.60417vw;
  width: 26.04167vw;
  margin: auto;
}

.forget_password_main_container .forgor_password_button_container {
  display: -webkit-flex;
  display: flex;
  height: 20.83333vw;
  width: 26.04167vw;
  margin: auto;
  -webkit-align-items: flex-start;
          align-items: flex-start;
  -webkit-justify-content: flex-end;
          justify-content: flex-end;
}

.success_message_container {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  height: 17.1875vw;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: space-between;
          justify-content: space-between;
}

.success_message_container .successful_label {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  height: 5.20833vw;
  font-family: 'Roboto', sans-serif;
  font-size: 1.30208vw;
  font-weight: 500;
  color: #FBC02D;
}

.success_message_container .successful_message {
  font-family: 'Roboto', sans-serif;
  font-size: 1.30208vw;
  font-weight: 300;
  color: #b4b4b4;
  text-align: center;
}

.broken_link_main_container {
  display: -webkit-flex;
  display: flex;
  height: 36.45833vw;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
}

.broken_link_main_container .broken_link_label {
  font-family: 'Roboto', sans-serif;
  font-size: 1.30208vw;
  font-weight: 500;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  color: #FBC02D;
}

.reset_password_main_container {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  height: 36.45833vw;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
}

.reset_password_main_container .reset_password_label {
  display: -webkit-flex;
  display: flex;
  height: 5.20833vw;
  width: 26.04167vw;
  font-family: 'Roboto', sans-serif;
  font-size: 1.30208vw;
  font-weight: 500;
  color: black;
  -webkit-align-items: center;
          align-items: center;
}

.reset_password_main_container .reset_password_form {
  width: 26.04167vw;
}

.reset_password_main_container .reset_button_container {
  width: 26.04167vw;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: flex-end;
          justify-content: flex-end;
}

.navbar_main_container {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-align-items: center;
          align-items: center;
  margin-top: 1.04167vw;
  height: 5.20833vw;
}

.navbar_main_container .logo_container img {
  height: 4.16667vw;
  width: 7.8125vw;
}

.navbar_main_container .nav_bar_container .profile_name {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  font-size: 1.14583vw;
  font-weight: 600;
  color: #559187;
}

.navbar_main_container .nav_bar_container .profile_name .profile_letter {
  padding: 0.52083vw 0.72917vw;
  background-color: #559187;
  border-radius: 1.30208vw;
  margin-right: 0.26042vw;
  color: #ffffff;
}

.navbar_main_container .nav_bar_container .profile_name .caret-down-font {
  font-size: 1.5625vw;
  color: gray;
  margin-left: 0.52083vw;
}

.navbar_main_container .nav_bar_container .dropdown-menu {
  background: #ffffff;
  border-radius: 0;
  border-bottom-left-radius: 0.52083vw;
  border-bottom-right-radius: 0.52083vw;
}

.navbar_main_container .nav_bar_container .dropdown-item {
  font-family: 'Roboto', sans-serif;
  font-size: 0.9375vw;
  font-weight: 400;
}

.main_nav_bar_container {
  height: 5.20833vw;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
}

.nav_item_main_container {
  display: -webkit-inline-flex;
  display: inline-flex;
  height: 2.60417vw;
  -webkit-align-items: center;
          align-items: center;
}

.nav_item_main_container .nav_name {
  display: inline-block;
  min-width: 10.41667vw;
  font-family: 'Roboto', sans-serif;
  font-size: 1.35417vw;
  font-weight: 400;
  text-align: center;
}

.nave_container {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-align-items: center;
          align-items: center;
}

.sub_navbar_container {
  height: 3.90625vw;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
}

.sub_navbar_container .nav_dropdown {
  font-size: 1.25vw;
  width: 15.625vw;
  height: 2.60417vw;
  border-radius: 0.26042vw;
  padding-left: 0.52083vw;
  font-weight: 600;
}

.nav_item_yellow_active {
  color: #FBC02D;
  cursor: pointer;
  font-weight: 700 !important;
}

.nav_item_yellow_active:hover {
  color: #FBC02D;
  cursor: pointer;
  font-weight: 400;
}

.nav_item_yellow {
  font-weight: 400;
  color: #000;
  cursor: pointer;
}

.nav_item_yellow:hover {
  font-weight: 400;
  color: #FBC02D;
  cursor: pointer;
}

.nav_item_black_active {
  font-weight: 700 !important;
  color: #000000;
  cursor: pointer;
}

.nav_item_black_active:hover {
  font-weight: 400;
  color: rgba(0, 0, 0, 0.74);
  cursor: pointer;
}

.nav_item_black {
  font-weight: 300;
  color: black;
  cursor: pointer;
}

.nav_item_black:hover {
  font-weight: 300;
  color: black;
  cursor: pointer;
}

.border_yellow {
  display: inline-block;
  border-left: 0.15625vw solid transparent;
  width: 0.05208vw;
  height: 1.04167vw;
  margin-left: 0.52083vw;
  margin-right: 0.52083vw;
}

.border_black {
  display: inline-block;
  border-left: 0.15625vw solid black;
  width: 0.05208vw;
  height: 2.08333vw;
  margin-left: 0.52083vw;
  margin-right: 0.52083vw;
}

.filter_main_container {
  display: -webkit-flex;
  display: flex;
  height: 2.60417vw;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: flex-end;
          justify-content: flex-end;
  font-family: 'Roboto', sans-serif;
  font-size: 1.04167vw;
  font-weight: 400;
  border-bottom: 0.5px solid rgba(0, 0, 0, 0.9);
}

.filter_main_container div, .filter_main_container i {
  cursor: pointer;
}

.panel_filter_full_height {
  height: 100vh;
  width: 26.04167vw;
  background: #EEEEEE;
  z-index: 99;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
}

.close_filter_and_clear_filters {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-align-items: center;
          align-items: center;
  height: 2.60417vw;
  padding-left: 0.52083vw;
  padding-right: 0.52083vw;
  -webkit-justify-content: space-between;
          justify-content: space-between;
}

.close_filter_and_clear_filters .clear_filter {
  font-family: 'Roboto', sans-serif;
  font-size: 1.04167vw;
  font-weight: 400;
  color: #F44336;
  cursor: pointer;
}

.close_filter_and_clear_filters .clear_filter_icon {
  color: #FBC02D;
  cursor: pointer;
}

.date_filter_main_container {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  padding: 0.52083vw;
  border-bottom: 0.5px solid rgba(0, 0, 0, 0.9);
}

.date_filter_main_container .date_filter_label {
  display: -webkit-flex;
  display: flex;
  height: 2.60417vw;
  -webkit-align-items: center;
          align-items: center;
  border-bottom: 0.5px solid rgba(0, 0, 0, 0.9);
  border-top: 0.5px solid rgba(0, 0, 0, 0.9);
  font-family: 'Roboto', sans-serif;
  font-size: 1.04167vw;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.9);
}

.date_form_container {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  height: 2.60417vw;
  width: 100%;
}

.date_form_container .date_label {
  display: -webkit-flex;
  display: flex;
  height: 2.60417vw;
  width: 30%;
  -webkit-align-items: center;
          align-items: center;
  font-family: 'Roboto', sans-serif;
  font-size: 1.04167vw;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.9);
}

.date_form_container .date_input {
  display: -webkit-flex;
  display: flex;
  height: 2.60417vw;
  width: 70%;
}

.publication_main_filter_container {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  width: 100%;
  height: 20.83333vw;
  padding: 0.52083vw;
}

.publication_main_filter_container .publication_filter_label {
  display: -webkit-flex;
  display: flex;
  height: 2.60417vw;
  -webkit-align-items: center;
          align-items: center;
  border-top: 0.5px solid rgba(0, 0, 0, 0.9);
  border-bottom: 0.5px solid rgba(0, 0, 0, 0.9);
  font-family: 'Roboto', sans-serif;
  font-size: 1.04167vw;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.9);
}

.category_main_filter_container {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  width: 100%;
  height: 20.83333vw;
  padding: 0.52083vw;
}

.category_main_filter_container .category_filter_label {
  display: -webkit-flex;
  display: flex;
  height: 2.60417vw;
  -webkit-align-items: center;
          align-items: center;
  border-top: 0.5px solid rgba(0, 0, 0, 0.9);
  border-bottom: 0.5px solid rgba(0, 0, 0, 0.9);
  font-family: 'Roboto', sans-serif;
  font-size: 1.04167vw;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.9);
}

.price_filter_dropdown_container {
  margin-top: 1.5625vw;
  display: -webkit-flex;
  display: flex;
}

.price_filter_dropdown_container .filter_dropdown_single_box_first {
  width: 13.02083vw;
}

.clear_filter {
  width: 8.33333vw;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  background-color: #FBC02D;
  border-radius: 0.52083vw;
  margin-left: 1.04167vw;
  cursor: pointer;
}

.post_main_container {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  min-height: 26.04167vw;
  background: red;
}

.no_data_container {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  height: 26.04167vw;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  font-family: 'Roboto', sans-serif;
  font-size: 1.30208vw;
  font-weight: 500;
  color: #FBC02D;
}

.main_input_with_drop_down_container {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  height: 2.60417vw;
  width: 100%;
  background: #EEEEEE;
}

.input_container_for_dropdown {
  display: -webkit-flex;
  display: flex;
  height: inherit;
  width: 100%;
  -webkit-align-items: center;
          align-items: center;
}

.input_container_for_dropdown input {
  display: -webkit-flex;
  display: flex;
  height: 2.60417vw;
  width: 100%;
  -webkit-align-items: center;
          align-items: center;
  font-family: 'Roboto', sans-serif;
  font-size: 1.04167vw;
  font-weight: 300;
  width: 100%;
  border: none;
  outline: none;
}

.input_container_for_dropdown input::-webkit-input-placeholder {
  display: -webkit-flex;
  display: flex;
  height: 2.60417vw;
  width: 100%;
  -webkit-align-items: center;
          align-items: center;
  font-family: 'Roboto', sans-serif;
  font-size: 1.04167vw;
  font-weight: 300;
  width: 100%;
  border: none;
  outline: none;
}

.input_container_for_dropdown input:-ms-input-placeholder {
  display: flex;
  height: 2.60417vw;
  width: 100%;
  align-items: center;
  font-family: 'Roboto', sans-serif;
  font-size: 1.04167vw;
  font-weight: 300;
  width: 100%;
  border: none;
  outline: none;
}

.input_container_for_dropdown input::placeholder {
  display: -webkit-flex;
  display: flex;
  height: 2.60417vw;
  width: 100%;
  -webkit-align-items: center;
          align-items: center;
  font-family: 'Roboto', sans-serif;
  font-size: 1.04167vw;
  font-weight: 300;
  width: 100%;
  border: none;
  outline: none;
}

.drop_down_list {
  display: -webkit-flex;
  display: flex;
  width: 100%;
  background: #EEEEEE;
  z-index: 40;
}

.drop_down_list ul {
  list-style-type: none;
  width: 100%;
}

.drop_down_list .category_select .level_one_type {
  background-color: #757575;
  display: -webkit-flex;
  display: flex;
  height: 2.60417vw;
  -webkit-align-items: center;
          align-items: center;
  padding-left: 2.60417vw;
  border-top: 0.5px solid rgba(0, 0, 0, 0.9);
  border-bottom: 0.5px solid rgba(0, 0, 0, 0.9);
  font-family: 'Roboto', sans-serif;
  font-size: 1.04167vw;
  font-weight: 300;
  color: #000000;
  cursor: pointer;
}

.drop_down_list .category_select .level_two_type {
  display: -webkit-flex;
  display: flex;
  height: 2.60417vw;
  -webkit-align-items: center;
          align-items: center;
  padding-left: 5.20833vw;
  border-bottom: 0.5px dashed rgba(0, 0, 0, 0.9);
  font-family: 'Roboto', sans-serif;
  font-size: 1.04167vw;
  font-weight: 300;
  color: #000000;
  cursor: pointer;
}

.drop_down_list .category_select .level_three_type {
  display: -webkit-flex;
  display: flex;
  height: 2.60417vw;
  -webkit-align-items: center;
          align-items: center;
  padding-left: 7.8125vw;
  border-bottom: 0.5px dashed rgba(0, 0, 0, 0.9);
  font-family: 'Roboto', sans-serif;
  font-size: 1.04167vw;
  font-weight: 300;
  color: #000000;
  cursor: pointer;
}

.drop_down_list .category_select li {
  display: -webkit-flex;
  display: flex;
  height: 2.60417vw;
  -webkit-align-items: center;
          align-items: center;
  padding-left: 10.41667vw;
  border-bottom: 0.5px dashed rgba(0, 0, 0, 0.5);
  font-family: 'Roboto', sans-serif;
  font-size: 1.04167vw;
  font-weight: 300;
  color: rgba(0, 0, 0, 0.9);
  cursor: pointer;
}

.drop_down_list li {
  display: -webkit-flex;
  display: flex;
  height: 2.60417vw;
  -webkit-align-items: center;
          align-items: center;
  padding-left: 0.52083vw;
  border-bottom: 0.5px solid rgba(0, 0, 0, 0.9);
  font-family: 'Roboto', sans-serif;
  font-size: 1.04167vw;
  font-weight: 300;
  color: rgba(0, 0, 0, 0.9);
  cursor: pointer;
}

.loader_container {
  display: -webkit-flex;
  display: flex;
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  z-index: 50;
  background: #EEEEEE;
  overflow: hidden;
}

/*********************************************
************** Desktop View  *****************
*********************************************/
@media only screen and (min-width: 600px) {
  .text-h2 {
    font-size: 1.5625vw;
    text-align: center;
  }
  .trial_users .btn_container {
    display: -webkit-flex;
    display: flex;
    width: 100%;
    height: 4.16667vw;
    margin-top: 3.125vw;
    margin-bottom: 3.125vw;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    border-bottom: 0.5px solid #00C950;
    border-top: 0.5px solid #00C950;
  }
  .trial_users .btn_container .trial_text {
    display: -webkit-flex;
    display: flex;
    font-family: 'Roboto', sans-serif;
    font-size: 1.30208vw;
    font-weight: 500;
    height: 2.60417vw;
    -webkit-align-items: center;
            align-items: center;
    color: #FBC02D;
    display: flex;
  }
  .border-red {
    border: 2px solid red;
  }
  .trial_btn {
    color: #000;
    background-color: #FBC02D;
    border-color: #FBC02D;
    display: inline-block;
    font-weight: 400;
    text-align: center;
    vertical-align: middle;
    -webkit-user-select: none;
    -ms-user-select: none;
         user-select: none;
    padding: 0.52083vw;
    font-size: 1.04167vw;
    line-height: 1.5;
    border-radius: 0.26042vw;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    cursor: pointer;
  }
  .trial_btn:active {
    display: -webkit-flex;
    display: flex;
    font-family: 'Roboto', sans-serif;
    font-size: 1.04167vw;
    font-weight: 500;
    height: 2.60417vw;
    -webkit-align-items: center;
            align-items: center;
    color: #fff;
    background-color: #FBC02D;
    border: solid 0.05208vw #FBC02D;
    padding: 0 0.625vw;
    border-radius: 0.78125vw;
    cursor: pointer;
  }
  .trial_btn:hover {
    color: #000;
    border: solid 0.05208vw #FBC02D;
  }
  .user_upload_container {
    display: -webkit-flex;
    display: flex;
    width: 100%;
    height: 4.16667vw;
    margin-top: 3.125vw;
    margin-bottom: 3.125vw;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    border-bottom: 0.5px solid #00C950;
    border-top: 0.5px solid #00C950;
  }
  .user_upload_container .vwa_upload_label {
    display: -webkit-flex;
    display: flex;
    font-family: 'Roboto', sans-serif;
    font-size: 1.30208vw;
    font-weight: 500;
    height: 2.60417vw;
    -webkit-align-items: center;
            align-items: center;
    color: #FBC02D;
    display: flex;
  }
}

/*********************************************
************** Mobile View  ******************
*********************************************/
@media only screen and (max-width: 600px) {
  .text-h2 {
    font-size: 3.125vw;
    text-align: center;
  }
  .trial_users .btn_container {
    display: -webkit-flex;
    display: flex;
    width: 100%;
    height: 7.8125vw;
    margin-top: 3.125vw;
    margin-bottom: 3.125vw;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    border-bottom: 0.5px solid #00C950;
    border-top: 0.5px solid #00C950;
  }
  .trial_users .btn_container .trial_text {
    display: -webkit-flex;
    display: flex;
    font-family: 'Roboto', sans-serif;
    font-size: 3.125vw;
    font-weight: 500;
    height: 2.60417vw;
    -webkit-align-items: center;
            align-items: center;
    color: #FBC02D;
  }
  .border-red {
    border: 2px solid red;
  }
  .trial_btn {
    color: #000;
    background-color: #FBC02D;
    border-color: #FBC02D;
    display: inline-block;
    font-weight: 400;
    text-align: center;
    vertical-align: middle;
    -webkit-user-select: none;
    -ms-user-select: none;
         user-select: none;
    padding: 1.04167vw;
    font-size: 3.125vw;
    line-height: 1.5;
    border-radius: 0.26042vw;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    cursor: pointer;
  }
  .trial_btn:active {
    display: -webkit-flex;
    display: flex;
    font-family: 'Roboto', sans-serif;
    font-size: 1.04167vw;
    font-weight: 500;
    height: 2.60417vw;
    -webkit-align-items: center;
            align-items: center;
    color: #fff;
    background-color: #FBC02D;
    border: solid 0.05208vw #FBC02D;
    padding: 0 0.625vw;
    border-radius: 0.78125vw;
    cursor: pointer;
  }
  .trial_btn:hover {
    color: #000;
    border: solid 0.05208vw #FBC02D;
  }
  .user_upload_container {
    display: -webkit-flex;
    display: flex;
    width: 100%;
    height: 7.8125vw;
    margin-top: 3.125vw;
    margin-bottom: 3.125vw;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    border-bottom: 0.5px solid #00C950;
    border-top: 0.5px solid #00C950;
  }
  .user_upload_container .vwa_upload_label {
    display: -webkit-flex;
    display: flex;
    font-family: 'Roboto', sans-serif;
    font-size: 3.125vw;
    font-weight: 500;
    height: 2.60417vw;
    -webkit-align-items: center;
            align-items: center;
    color: #FBC02D;
  }
}

.error-msg {
  color: red;
  font-size: 12px;
  text-align: center;
  margin-top: 5px;
  margin-bottom: 5px;
}

/*********************************************
************** Desktop View  *****************
*********************************************/
@media only screen and (min-width: 600px) {
  .pagination-container {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    margin: 0vw auto 2.60417vw;
    width: 60%;
  }
  .pre_next {
    font-size: 1.5625vw;
    cursor: pointer;
    font-weight: 600;
  }
  .pre_next .icon_box {
    border: 0.05208vw solid #0000007c;
    padding: 0.05208vw 0.52083vw;
  }
}

.single_page_number_news {
  cursor: pointer;
}

.single_page_number_active_news {
  cursor: pointer;
  font-weight: 900;
  font-size: 1.5625vw;
}

/*********************************************
  ************** Mobile View  ******************
  *********************************************/
@media only screen and (max-width: 600px) {
  .pagination-container {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    margin: 0vw auto;
    width: 90%;
  }
  .pre_next {
    font-size: 4.16667vw;
    cursor: pointer;
    font-weight: 600;
  }
  .pre_next .icon_box {
    border: 0.05208vw solid #0000007c;
    padding: 0.26042vw 1.04167vw;
  }
}

.home-pagination,
.resources-pagination {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  padding-top: 2.60417vw;
}

.home-pagination .pagination_main_display_container,
.resources-pagination .pagination_main_display_container {
  display: -webkit-flex;
  display: flex;
  font-size: 0.9375vw;
}

.home-pagination .pagination_main_display_container .single_page_number,
.resources-pagination .pagination_main_display_container .single_page_number {
  cursor: pointer;
  padding: 0 0.52083vw;
}

.home-pagination .pagination_main_display_container .single_page_number_active,
.resources-pagination .pagination_main_display_container .single_page_number_active {
  cursor: pointer;
  font-weight: bolder;
  padding: 0 0.52083vw;
}

.articles-pagination {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  padding-top: 0.52083vw;
  width: 100%;
}

.articles-pagination .pagination_main_display_container {
  display: -webkit-flex;
  display: flex;
  font-size: 0.9375vw;
}

.articles-pagination .pagination_main_display_container .single_page_number {
  cursor: pointer;
  padding: 0.52083vw;
}

.articles-pagination .pagination_main_display_container .single_page_number_active {
  cursor: pointer;
  font-weight: bolder;
  padding: 0.52083vw;
}

.pagination_main_display_container {
  display: -webkit-flex;
  display: flex;
  font-size: 0.9375vw;
}

.pagination_main_display_container .single_page_number {
  cursor: pointer;
  padding: 1.5625vw;
}

.pagination_main_display_container .single_page_number_active {
  cursor: pointer;
  font-weight: bolder;
  padding: 1.5625vw;
}

.PriceList-pagination {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  padding-bottom: 1.5625vw;
  width: 100%;
}

.PriceList-pagination .pagination_main_display_container {
  display: -webkit-flex;
  display: flex;
  font-size: 0.9375vw;
}

.PriceList-pagination .pagination_main_display_container .single_page_number {
  cursor: pointer;
  padding: 0.52083vw;
}

.PriceList-pagination .pagination_main_display_container .single_page_number_active {
  cursor: pointer;
  font-weight: bolder;
  padding: 0.52083vw;
}

.single_page_number_news {
  cursor: pointer;
}

.single_page_number_active_news {
  cursor: pointer;
  font-weight: bolder;
}

.login_page_header_logo_container {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  margin-top: 1.5625vw;
  height: 5.20833vw;
}

.login_page_header_logo_container img {
  height: 4.16667vw;
  width: 7.8125vw;
}

.login_page_form_main_container {
  margin-top: 0.52083vw;
  margin-bottom: 4.16667vw;
}

.login_page_form_main_container .welcome_container {
  display: -webkit-flex;
  display: flex;
  height: 10.41667vw;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  font-family: 'Roboto', sans-serif;
  font-size: 2.08333vw;
  font-weight: 500;
}

.login_page_form_main_container .form_container {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  width: 23.4375vw;
  margin: auto;
}

.login_page_form_main_container .button_container {
  width: auto;
}

.forget_password {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: flex-end;
          justify-content: flex-end;
  font-family: 'Roboto', sans-serif;
  font-size: 0.9375vw;
  font-weight: 500;
  cursor: pointer;
}

.profile_name_label {
  display: -webkit-flex;
  display: flex;
  height: 2.60417vw;
  -webkit-align-items: center;
          align-items: center;
  font-family: 'Roboto', sans-serif;
  font-size: 1.30208vw;
  font-weight: 500;
  color: #FBC02D;
}

.profile {
  min-height: 18.22917vw;
  margin: 2.60417vw;
  padding: 2.08333vw;
  border-radius: 10px;
  box-shadow: 0px 0px 20px -5px rgba(150, 150, 150, 0.57);
}

.profile .profile_label {
  display: -webkit-flex;
  display: flex;
  height: 2.60417vw;
  padding-bottom: 1.5625vw;
  -webkit-align-items: center;
          align-items: center;
  font-family: 'Roboto', sans-serif;
  font-size: 1.30208vw;
  font-weight: 500;
  border-bottom: 1px solid #b8b8b8;
  color: #000000e6;
}

.profile .active_since {
  display: -webkit-flex;
  display: flex;
  height: 2.60417vw;
  -webkit-align-items: center;
          align-items: center;
  font-family: 'Roboto', sans-serif;
  font-size: 0.9375vw;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.9);
}

.profile .profile_email {
  display: -webkit-flex;
  display: flex;
  height: 2.60417vw;
  -webkit-align-items: center;
          align-items: center;
  font-family: 'Roboto', sans-serif;
  font-size: 0.9375vw;
  font-weight: 300;
  color: rgba(0, 0, 0, 0.9);
}

.profile .profile_terms_and_condition {
  display: -webkit-flex;
  display: flex;
  height: 2.60417vw;
  -webkit-align-items: center;
          align-items: center;
  font-family: 'Roboto', sans-serif;
  font-size: 0.9375vw;
  font-weight: 300;
  color: rgba(0, 0, 0, 0.9);
}

.profile .update_profile {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  display: flex;
  height: 2.60417vw;
  align-items: center;
  font-family: 'Roboto', sans-serif;
  font-size: 0.9375vw;
  font-weight: 400;
}

.profile .update_profile .profile_manage_profile {
  color: #00C950;
  cursor: pointer;
}

.profile .update_profile .profile_change_password {
  color: #F44336;
  cursor: pointer;
}

.publications_section {
  min-height: 18.22917vw;
  margin: 2.60417vw;
  padding: 2.08333vw;
  border-radius: 10px;
  box-shadow: 0px 0px 20px -5px rgba(150, 150, 150, 0.57);
}

.publications_section .publication_label {
  display: -webkit-flex;
  display: flex;
  height: 2.60417vw;
  padding-bottom: 1.5625vw;
  -webkit-align-items: center;
          align-items: center;
  font-family: 'Roboto', sans-serif;
  font-size: 1.30208vw;
  font-weight: 500;
  border-bottom: 1px solid #b8b8b8;
  color: #000000e6;
}

.publications_section .publication_name {
  display: -webkit-flex;
  display: flex;
  height: 2.60417vw;
  -webkit-align-items: center;
          align-items: center;
  font-family: 'Roboto', sans-serif;
  font-size: 0.9375vw;
  font-weight: 300;
  color: rgba(0, 0, 0, 0.9);
}

.roles {
  min-height: 18.22917vw;
  margin: 2.60417vw;
  padding: 2.08333vw;
  border-radius: 10px;
  box-shadow: 0px 0px 20px -5px rgba(150, 150, 150, 0.57);
}

.roles .roles_label {
  display: -webkit-flex;
  display: flex;
  height: 2.60417vw;
  padding-bottom: 1.5625vw;
  -webkit-align-items: center;
          align-items: center;
  font-family: 'Roboto', sans-serif;
  font-size: 1.30208vw;
  font-weight: 500;
  border-bottom: 1px solid #b8b8b8;
  color: #000000e6;
}

.roles .roles_list {
  display: -webkit-flex;
  display: flex;
  height: 2.60417vw;
  -webkit-align-items: center;
          align-items: center;
  font-family: 'Roboto', sans-serif;
  font-size: 0.9375vw;
  font-weight: 300;
  color: rgba(0, 0, 0, 0.9);
}

.stats {
  min-height: 18.22917vw;
  margin: 2.60417vw;
  padding: 2.08333vw;
  border-radius: 10px;
  box-shadow: 0px 0px 20px -5px rgba(150, 150, 150, 0.57);
}

.stats .stats_label {
  display: -webkit-flex;
  display: flex;
  height: 2.60417vw;
  padding-bottom: 1.5625vw;
  -webkit-align-items: center;
          align-items: center;
  font-family: 'Roboto', sans-serif;
  font-size: 1.30208vw;
  font-weight: 500;
  border-bottom: 1px solid #b8b8b8;
  color: #000000e6;
}

.stats .stats_list {
  display: -webkit-flex;
  display: flex;
  height: 2.60417vw;
  -webkit-align-items: center;
          align-items: center;
  font-family: 'Roboto', sans-serif;
  font-size: 0.9375vw;
  font-weight: 300;
  color: rgba(0, 0, 0, 0.9);
}

.terms_and_condition_label {
  display: -webkit-flex;
  display: flex;
  height: 2.60417vw;
  -webkit-align-items: center;
          align-items: center;
  font-family: 'Roboto', sans-serif;
  font-size: 0.9375vw;
  font-weight: 300;
  color: rgba(0, 0, 0, 0.9);
  cursor: pointer;
}

.modal_body_main_container .modal_body_label {
  display: -webkit-flex;
  display: flex;
  height: 5.20833vw;
  -webkit-align-items: center;
          align-items: center;
  border-bottom: 0.5px solid rgba(0, 0, 0, 0.9);
  font-family: 'Roboto', sans-serif;
  font-size: 1.30208vw;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.9);
  cursor: pointer;
}

.modal_body_main_container .terms_and_condition_body {
  padding: 2.60417vw;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  border-bottom: 0.5px solid rgba(0, 0, 0, 0.9);
  font-family: 'Roboto', sans-serif;
  font-size: 0.9375vw;
  font-weight: 300;
  text-align: justify;
  color: rgba(0, 0, 0, 0.9);
}

.modal_body_main_container .button_container {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  height: 5.20833vw;
}

.manage_profile_label {
  display: -webkit-flex;
  display: flex;
  height: 2.60417vw;
  -webkit-align-items: center;
          align-items: center;
  font-family: 'Roboto', sans-serif;
  font-size: 0.9375vw;
  font-weight: 400;
  color: #F44336;
  cursor: pointer;
}

.change_password_form_container {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
}

.change_password_form_container .change_password_heading {
  display: -webkit-flex;
  display: flex;
  height: 2.60417vw;
  -webkit-align-items: center;
          align-items: center;
  font-family: 'Roboto', sans-serif;
  font-size: 1.30208vw;
  font-weight: 400;
  color: #FBC02D;
}

.change_password_form_container .change_password_text {
  display: -webkit-flex;
  display: flex;
  height: 5.20833vw;
  -webkit-align-items: center;
          align-items: center;
  font-family: 'Roboto', sans-serif;
  font-size: 0.9375vw;
  font-weight: 300;
  color: rgba(0, 0, 0, 0.9);
}

.change_password_form_container .change_password_button_container {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: flex-end;
          justify-content: flex-end;
}

.super_admin_user_stats_main_container .label {
  display: -webkit-flex;
  display: flex;
  height: 2.60417vw;
  -webkit-align-items: center;
          align-items: center;
  border-bottom: 0.5px solid rgba(0, 0, 0, 0.9);
  font-family: 'Roboto', sans-serif;
  font-size: 1.30208vw;
  font-weight: 300;
  color: rgba(0, 0, 0, 0.9);
}

.super_admin_user_stats_main_container .super_admin_stats_container {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: space-between;
          justify-content: space-between;
}

.super_admin_user_stats_main_container .super_admin_stats_container .stats_box {
  margin: 0.52083vw;
  display: -webkit-flex;
  display: flex;
  height: 8.85417vw;
  width: 33%;
}

.label {
  display: -webkit-flex;
  display: flex;
  height: 2.60417vw;
  -webkit-align-items: center;
          align-items: center;
  border-bottom: 0.5px solid rgba(0, 0, 0, 0.9);
  font-family: 'Roboto', sans-serif;
  font-size: 1.30208vw;
  font-weight: 300;
  color: rgba(0, 0, 0, 0.9);
}

.stats_box_container {
  display: -webkit-flex;
  display: flex;
  height: 100%;
  width: 100%;
  border-radius: 0.78125vw;
  -webkit-flex-direction: column;
          flex-direction: column;
}

.stats_box_container .stats_box_label {
  display: -webkit-flex;
  display: flex;
  height: 3.64583vw;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: flex-start;
          justify-content: flex-start;
  padding-left: 2.60417vw;
  font-family: 'Big Shoulders Text', cursive;
  font-size: 2.08333vw;
  font-weight: 300;
  color: rgba(0, 0, 0, 0.9);
}

.stats_box_container .stats_box_count {
  display: -webkit-flex;
  display: flex;
  height: 3.64583vw;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  font-family: 'Big Shoulders Text', cursive;
  font-size: 4.16667vw;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.9);
}

.super_admin_publications_stats_container {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-align-items: center;
          align-items: center;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
}

.super_admin_publications_stats_container .publications_stats_box {
  margin: 0.52083vw;
  display: -webkit-flex;
  display: flex;
  height: 8.85417vw;
  width: 23.8%;
}

.publishing_stats_container {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-align-items: center;
          align-items: center;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
}

.publishing_stats_container .publishing_box {
  margin: 0.52083vw;
  display: -webkit-flex;
  display: flex;
  height: 13.02083vw;
  width: 32.2%;
}

.publishing_stats_box_container {
  background: #FBC02D;
  display: -webkit-flex;
  display: flex;
  height: 100%;
  width: 100%;
  border-radius: 0.78125vw;
  -webkit-flex-direction: column;
          flex-direction: column;
}

.publishing_stats_box_container .publishing_state_label {
  display: -webkit-flex;
  display: flex;
  height: 3.64583vw;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: flex-start;
          justify-content: flex-start;
  padding-left: 2.60417vw;
  font-family: 'Big Shoulders Text', cursive;
  font-size: 2.08333vw;
  font-weight: 300;
  color: rgba(0, 0, 0, 0.9);
}

.publishing_stats_box_container .published_label_container {
  display: -webkit-flex;
  display: flex;
  height: 2.60417vw;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-align-items: center;
          align-items: center;
  padding-left: 2.60417vw;
}

.publishing_stats_box_container .published_label_container .label {
  display: -webkit-flex;
  display: flex;
  width: 70%;
  font-family: 'Big Shoulders Text', cursive;
  font-size: 1.5625vw;
  font-weight: 300;
  color: rgba(0, 0, 0, 0.9);
  border: none;
}

.publishing_stats_box_container .published_label_container .count {
  display: -webkit-flex;
  display: flex;
  width: 30%;
  font-family: 'Roboto', sans-serif;
  font-size: 1.5625vw;
  font-weight: 300;
  color: rgba(0, 0, 0, 0.9);
  border: none;
}

.add_container {
  display: -webkit-flex;
  display: flex;
  height: 100%;
  width: 100%;
  -webkit-flex-direction: row;
          flex-direction: row;
}

.add_container .add_box {
  border-radius: 0.78125vw;
  margin: 0.52083vw;
  display: -webkit-flex;
  display: flex;
  height: 6.77083vw;
  width: 23.8%;
}

.add_publication_label_container {
  background: #F44336;
  border-radius: 0.78125vw;
  display: -webkit-flex;
  display: flex;
  height: 100%;
  width: 100%;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  font-family: 'Big Shoulders Text', cursive;
  font-size: 2.60417vw;
  font-weight: 300;
  color: white;
  cursor: pointer;
}

.add_publication_form_main_container {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
}

.add_publication_form_main_container .add_publications_label {
  display: -webkit-flex;
  display: flex;
  height: 2.60417vw;
  -webkit-align-items: center;
          align-items: center;
  border-bottom: 0.5px solid rgba(0, 0, 0, 0.9);
  font-family: 'Roboto', sans-serif;
  font-size: 1.30208vw;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.9);
}

.add_publication_form_main_container .add_publication_form {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  padding-top: 2.60417vw;
  padding-bottom: 2.60417vw;
}

.add_publication_form_main_container .add_publication_form .publication_textarea {
  height: 7.8125vw;
}

.add_publication_button {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: flex-end;
          justify-content: flex-end;
}

.update_publication_form_main_container {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
}

.update_publication_label {
  display: -webkit-flex;
  display: flex;
  height: 2.60417vw;
  -webkit-align-items: center;
          align-items: center;
  border-bottom: 0.5px solid rgba(0, 0, 0, 0.9);
  font-family: 'Roboto', sans-serif;
  font-size: 1.30208vw;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.9);
}

.update_publication_form {
  display: -webkit-flex;
  display: flex;
  width: 80%;
  margin: auto;
  -webkit-flex-direction: column;
          flex-direction: column;
  padding-top: 1.5625vw;
  padding-bottom: 1.5625vw;
}

.update_publication_form .input_container {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: space-between;
          justify-content: space-between;
}

.update_publication_form .input_container .input_labele {
  font-family: 'Roboto', sans-serif;
  font-size: 1.04167vw;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.9);
  width: 30%;
}

.update_publication_form .input_container .input_tag {
  width: 60%;
}

.update_button_archive_container {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-align-items: center;
          align-items: center;
  width: 80%;
  margin: auto;
  -webkit-justify-content: space-between;
          justify-content: space-between;
}

.update_button_archive_container .publication_status {
  font-family: 'Roboto', sans-serif;
  font-size: 1.04167vw;
  font-weight: 400;
  color: #00C950;
  cursor: pointer;
}

.render_calendar_container {
  display: -webkit-flex;
  display: flex;
  width: 100%;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  border-bottom: 0.5px solid rgba(0, 0, 0, 0.9);
}

.render_calendar_label {
  display: -webkit-flex;
  display: flex;
  height: 2.60417vw;
  -webkit-align-items: center;
          align-items: center;
  font-family: 'Roboto', sans-serif;
  font-size: 1.30208vw;
  font-weight: 500;
  color: #FBC02D;
}

.calendar_add_new {
  display: -webkit-flex;
  display: flex;
  height: 2.60417vw;
  -webkit-align-items: center;
          align-items: center;
  font-family: 'Roboto', sans-serif;
  font-size: 1.30208vw;
  font-weight: 500;
  color: #FBC02D;
  cursor: pointer;
}

.render_country_year_calendar_container {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-justify-content: space-between;
          justify-content: space-between;
}

.render_data_main_container {
  display: -webkit-flex;
  display: flex;
  width: 32%;
  -webkit-flex-direction: column;
          flex-direction: column;
  margin-top: 2.60417vw;
  margin-bottom: 2.60417vw;
  min-height: 26.04167vw;
  max-height: 41.66667vw;
  overflow: auto;
  overflow-x: hidden;
}

.render_label {
  display: -webkit-flex;
  display: flex;
  height: 2.60417vw;
  -webkit-align-items: center;
          align-items: center;
  font-family: 'Roboto', sans-serif;
  font-size: 1.30208vw;
  font-weight: 500;
  color: #FBC02D;
  border-top: 0.5px solid rgba(0, 0, 0, 0.9);
  border-bottom: 0.5px solid rgba(0, 0, 0, 0.9);
}

.render_data {
  display: -webkit-flex;
  display: flex;
  height: 2.60417vw;
  -webkit-align-items: center;
          align-items: center;
  font-family: 'Roboto', sans-serif;
  font-size: 0.9375vw;
  font-weight: 300;
  color: rgba(0, 0, 0, 0.9);
  border-bottom: 0.5px solid rgba(0, 0, 0, 0.9);
  cursor: pointer;
}

.add_calendar_modal_container {
  display: -webkit-flex;
  display: flex;
  width: 100%;
  -webkit-flex-direction: column;
          flex-direction: column;
}

.add_calendar_modal_container .row_input_view {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  height: 3.90625vw;
  font-family: 'Roboto', sans-serif;
  font-size: 0.9375vw;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.9);
  border-bottom: 0.5px solid rgba(0, 0, 0, 0.9);
  margin-top: 0.26042vw;
  margin-bottom: 0.26042vw;
  width: 100%;
}

.add_calendar_modal_container .row_input_view .row_input_label {
  display: -webkit-flex;
  display: flex;
  font-family: 'Roboto', sans-serif;
  font-size: 0.83333vw;
  height: 1.04167vw;
  -webkit-align-items: center;
          align-items: center;
  padding-left: 0.52083vw;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.9);
}

.add_calendar_modal_container .row_input_view .row_input_container {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  padding-left: 0.52083vw;
}

.add_calender_label {
  display: -webkit-flex;
  display: flex;
  height: 2.60417vw;
  -webkit-align-items: center;
          align-items: center;
  font-family: 'Roboto', sans-serif;
  font-size: 1.30208vw;
  font-weight: 500;
  color: #FBC02D;
  border-bottom: 0.5px solid rgba(0, 0, 0, 0.9);
  margin-bottom: 1.04167vw;
}

.upload_calendar_label {
  display: -webkit-flex;
  display: flex;
  font-family: 'Roboto', sans-serif;
  font-size: 0.83333vw;
  height: 1.04167vw;
  -webkit-align-items: center;
          align-items: center;
  padding-left: 0.52083vw;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.9);
  margin-top: 0.26042vw;
}

.file_upload {
  display: -webkit-flex;
  display: flex;
  height: 2.60417vw;
  width: 100%;
  -webkit-align-items: center;
          align-items: center;
  font-family: 'Roboto', sans-serif;
  font-size: 1.04167vw;
  font-weight: 300;
  width: 100%;
  border: none;
  outline: none;
  padding: 0.52083vw;
  border-bottom: 0.5px solid rgba(0, 0, 0, 0.9);
}

.filter_container {
  display: -webkit-flex;
  display: flex;
  width: 100%;
  height: 2.60417vw;
  -webkit-align-items: center;
          align-items: center;
  -webkit-flex-direction: row;
          flex-direction: row;
  border-bottom: 0.5px solid rgba(0, 0, 0, 0.9);
}

.filter_container .filter_label {
  display: -webkit-flex;
  display: flex;
  padding-left: 1.04167vw;
  height: 2.60417vw;
  -webkit-align-items: center;
          align-items: center;
  font-family: 'Roboto', sans-serif;
  font-size: 1.04167vw;
  font-weight: 400;
  border-right: 0.5px solid rgba(0, 0, 0, 0.9);
}

.filter_container .date_filter_label {
  display: -webkit-flex;
  display: flex;
  padding-left: 1.04167vw;
  height: 2.60417vw;
  -webkit-align-items: center;
          align-items: center;
  font-family: 'Roboto', sans-serif;
  font-size: 1.04167vw;
  font-weight: 400;
  border-right: 0.5px solid rgba(0, 0, 0, 0.9);
}

.filter_container .filter_label:last-child {
  border: none;
}

.filter_container div {
  width: 20%;
}

.date_filter_label {
  display: -webkit-flex;
  display: flex;
  width: 100%;
  height: 2.60417vw;
  -webkit-align-items: center;
          align-items: center;
}

.post_area_container {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  width: 100%;
  min-height: 26.04167vw;
  border-top: 0.5px solid #e4e4e4e4;
  margin-top: 2.60417vw;
  padding-bottom: 2.60417vw;
}

.write_new_label_container {
  font-family: "Roboto", sans-serif;
  font-size: 1.30208vw;
  font-weight: 500;
  display: -webkit-flex;
  display: flex;
  height: 2.60417vw;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  color: #FBC02D;
  cursor: pointer;
}

.post_type_selector_main_container .post_type_label {
  display: -webkit-flex;
  display: flex;
  height: 2.60417vw;
  -webkit-align-items: center;
          align-items: center;
  font-family: "Roboto", sans-serif;
  font-size: 1.30208vw;
  font-weight: 500;
}

.post_type_form_container {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  height: 10.41667vw;
  -webkit-justify-content: center;
          justify-content: center;
}

.post_type_form_container .post_containers {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  width: 100%;
  border-bottom: 0.5px solid rgba(0, 0, 0, 0.9);
}

.post_type_form_container .post_containers .article_head {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  margin-right: 0.78125vw;
}

.post_type_form_container .post_containers .post_label {
  display: -webkit-flex;
  display: flex;
  height: 2.60417vw;
  -webkit-align-items: center;
          align-items: center;
  width: 40%;
  font-family: "Roboto", sans-serif;
  font-size: 0.9375vw;
  font-weight: 300;
  color: rgba(0, 0, 0, 0.9);
}

.post_type_form_container .post_containers .post_input {
  display: -webkit-flex;
  display: flex;
  height: 2.60417vw;
  -webkit-align-items: center;
          align-items: center;
  width: 10%;
}

.render_post_main_container {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: center;
          justify-content: center;
  height: 7.29167vw;
  width: 100%;
  border-bottom: 0.5px solid #2c2c2c33;
}

.headline_and_button_section {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  width: 100%;
  -webkit-justify-content: space-between;
          justify-content: space-between;
}

.headline_and_button_section .post_headline {
  display: -webkit-flex;
  display: flex;
  height: 3.125vw;
  -webkit-align-items: center;
          align-items: center;
  width: 80%;
  font-family: 'Roboto', sans-serif;
  font-size: 1.5625vw;
  font-weight: 600;
  color: #000000;
}

.delete-draft {
  cursor: pointer;
}

.delete-draft:hover i {
  color: red;
}

.post_info_section {
  display: -webkit-flex;
  display: flex;
  height: 1.30208vw;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: flex-start;
          justify-content: flex-start;
  font-family: 'Roboto', sans-serif;
  font-size: 0.9375vw;
  font-weight: 400;
  color: #6E707A;
}

.post_buttons {
  display: -webkit-flex;
  display: flex;
  height: 3.125vw;
  width: 12%;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  font-family: 'Roboto', sans-serif;
  font-size: 1.04167vw;
  color: white;
  font-weight: 600;
  border-radius: 0.26042vw;
  letter-spacing: 1.5px;
  box-shadow: 0px 0px 14px -10px rgba(0, 0, 0, 0.75);
  cursor: pointer;
}

.post_buttons:hover {
  display: -webkit-flex;
  display: flex;
  height: 3.125vw;
  width: 12%;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  font-family: 'Roboto', sans-serif;
  font-size: 1.04167vw;
  color: white;
  font-weight: 600;
  border-radius: 0.26042vw;
  letter-spacing: 1.5px;
  box-shadow: 0px 0px 14px -10px rgba(0, 0, 0, 0.75);
  cursor: pointer;
}

.post_buttons:active {
  display: -webkit-flex;
  display: flex;
  height: 3.125vw;
  width: 12%;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  font-family: 'Roboto', sans-serif;
  font-size: 0.9375vw;
  color: white;
  font-weight: 600;
  border-radius: 0.52083vw;
  letter-spacing: 1.5px;
  cursor: pointer;
}

.add_personnel_main_container {
  background: #00C950;
  border-radius: 0.78125vw;
  display: -webkit-flex;
  display: flex;
  height: 100%;
  width: 100%;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  font-family: 'Big Shoulders Text', cursive;
  font-size: 2.60417vw;
  font-weight: 300;
  color: white;
  cursor: pointer;
}

.basic_form_main_container .add_personell_basic_label {
  display: -webkit-flex;
  display: flex;
  height: 2.60417vw;
  font-family: 'Roboto', sans-serif;
  font-size: 1.5625vw;
  font-weight: 300;
  color: rgba(0, 0, 0, 0.9);
  border-bottom: 0.5px solid rgba(0, 0, 0, 0.9);
}

.basic_form_main_container .add_personnel_form {
  padding-top: 1.5625vw;
}

.journalist_type {
  display: -webkit-flex;
  display: flex;
  height: 2.60417vw;
  -webkit-align-items: center;
          align-items: center;
  -webkit-flex-direction: row;
          flex-direction: row;
  border-bottom: 0.5px solid rgba(0, 0, 0, 0.9);
}

.journalist_type .journalist_label {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  font-family: 'Roboto', sans-serif;
  font-size: 0.9375vw;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.9);
  width: 40%;
}

.journalist_type .journalist_input {
  width: 10%;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
}

.assign_publication_label {
  display: -webkit-flex;
  display: flex;
  height: 2.60417vw;
  -webkit-align-items: center;
          align-items: center;
  font-family: 'Roboto', sans-serif;
  font-size: 1.04167vw;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.9);
  border-bottom: 0.5px solid rgba(0, 0, 0, 0.9);
}

.publication_data_container {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  border-bottom: 0.5px solid rgba(0, 0, 0, 0.9);
  -webkit-justify-content: space-between;
          justify-content: space-between;
}

.publication_data_container .publication_label {
  display: -webkit-flex;
  display: flex;
  height: 2.60417vw;
  -webkit-align-items: center;
          align-items: center;
  width: 80%;
  font-family: 'Roboto', sans-serif;
  font-size: 1.04167vw;
  font-weight: 300;
  color: rgba(0, 0, 0, 0.9);
}

.publication_data_container .publication_input {
  display: -webkit-flex;
  display: flex;
  height: 2.60417vw;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  width: 10%;
}

.space_top {
  display: -webkit-flex;
  display: flex;
  height: 2.60417vw;
  border-bottom: 0.5px solid rgba(0, 0, 0, 0.9);
}

.button_container {
  display: -webkit-flex;
  display: flex;
  height: 5.20833vw;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: flex-end;
          justify-content: flex-end;
}

.administrator_role_lable {
  display: -webkit-flex;
  display: flex;
  height: 2.60417vw;
  -webkit-align-items: center;
          align-items: center;
  font-family: 'Roboto', sans-serif;
  font-size: 1.04167vw;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.9);
  border-bottom: 0.5px solid rgba(0, 0, 0, 0.9);
}

.back_button {
  display: -webkit-flex;
  display: flex;
  height: 2.60417vw;
  -webkit-align-items: center;
          align-items: center;
  font-family: 'Roboto', sans-serif;
  font-size: 1.04167vw;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.9);
  cursor: pointer;
}

.add_new_employee_label {
  display: -webkit-flex;
  display: flex;
  height: 2.60417vw;
  -webkit-align-items: center;
          align-items: center;
  font-family: 'Roboto', sans-serif;
  font-size: 1.30208vw;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.9);
  cursor: pointer;
}

.email_card_container {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  width: 100%;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  margin-bottom: 1.04167vw;
}

.email_list_container {
  width: 32%;
  display: -webkit-flex;
  display: flex;
  margin-top: 1.04167vw;
  box-shadow: 0 3px 10px #00000033;
  border-radius: 5px;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  padding: 1.04167vw;
}

.email_list_container .fa-times {
  cursor: pointer;
  color: gray;
  font-size: 1.125rem;
}

.add_email {
  display: -webkit-flex;
  display: flex;
  height: 2.34375vw;
  width: 12%;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  font-family: 'Roboto', sans-serif;
  font-size: 1.04167vw;
  color: white;
  font-weight: 600;
  border-radius: 0.26042vw;
  letter-spacing: 1.5px;
  box-shadow: 0px 0px 14px -10px rgba(0, 0, 0, 0.75);
  cursor: pointer;
}

.add_email:hover {
  display: -webkit-flex;
  display: flex;
  height: 2.34375vw;
  width: 12%;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  font-family: 'Roboto', sans-serif;
  font-size: 1.04167vw;
  color: white;
  font-weight: 600;
  border-radius: 0.26042vw;
  letter-spacing: 1.5px;
  box-shadow: 0px 0px 14px -10px rgba(0, 0, 0, 0.75);
  cursor: pointer;
}

.add_email:active {
  display: -webkit-flex;
  display: flex;
  height: 2.34375vw;
  width: 12%;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  font-family: 'Roboto', sans-serif;
  font-size: 0.9375vw;
  color: white;
  font-weight: 600;
  border-radius: 0.52083vw;
  letter-spacing: 1.5px;
  cursor: pointer;
}

.update_personnel_container {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  margin: 1.04167vw;
  padding: 1.04167vw;
  height: 6.25vw;
  border-radius: 10px;
  box-shadow: 0px 0px 20px -5px rgba(150, 150, 150, 0.57);
}

.update_info {
  -webkit-flex: auto;
          flex: auto;
}

.update_info .name {
  font-family: 'Roboto', sans-serif;
  font-size: 1.04167vw;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.9);
}

.update_info .publications {
  font-family: 'Roboto', sans-serif;
  font-size: 1.04167vw;
  font-weight: 300;
  color: rgba(0, 0, 0, 0.9);
}

.backgroud_green {
  background: #00c950;
}

.update_button {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  width: 9.375vw;
  -webkit-justify-content: center;
          justify-content: center;
}

.update_button .post_buttons {
  display: -webkit-flex;
  display: flex;
  height: 3.125vw;
  width: 100%;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  font-family: 'Roboto', sans-serif;
  font-size: 1.04167vw;
  color: white;
  font-weight: 600;
  border-radius: 0.26042vw;
  letter-spacing: 1.5px;
  box-shadow: 0px 0px 14px -10px rgba(0, 0, 0, 0.75);
  cursor: pointer;
}

.update_button .post_buttons:hover {
  display: -webkit-flex;
  display: flex;
  height: 3.125vw;
  width: 100%;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  font-family: 'Roboto', sans-serif;
  font-size: 1.04167vw;
  color: white;
  font-weight: 600;
  border-radius: 0.26042vw;
  letter-spacing: 1.5px;
  box-shadow: 0px 0px 14px -10px rgba(0, 0, 0, 0.75);
  cursor: pointer;
}

.update_button .post_buttons:active {
  display: -webkit-flex;
  display: flex;
  height: 3.125vw;
  width: 100%;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  font-family: 'Roboto', sans-serif;
  font-size: 0.9375vw;
  color: white;
  font-weight: 600;
  border-radius: 0.52083vw;
  letter-spacing: 1.5px;
  cursor: pointer;
}

.flex_property {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  width: 100%;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
}

.view_history_and_archive {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  width: 100%;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  border-bottom: 0.5px solid rgba(0, 0, 0, 0.9);
}

.view_history_and_archive .history {
  display: -webkit-flex;
  display: flex;
  height: 2.60417vw;
  -webkit-align-items: center;
          align-items: center;
  font-family: 'Roboto', sans-serif;
  font-size: 1.04167vw;
  font-weight: 300;
  color: #00C950;
  cursor: pointer;
}

.view_history_and_archive .archive {
  display: -webkit-flex;
  display: flex;
  height: 2.60417vw;
  -webkit-align-items: center;
          align-items: center;
  font-family: 'Roboto', sans-serif;
  font-size: 1.04167vw;
  font-weight: 300;
  color: #F44336;
  cursor: pointer;
}

.bacK_button_container {
  display: -webkit-flex;
  display: flex;
  height: 2.60417vw;
  -webkit-align-items: center;
          align-items: center;
  font-family: 'Roboto', sans-serif;
  font-size: 1.04167vw;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.9);
  border-bottom: 0.5px solid rgba(0, 0, 0, 0.9);
}

.post_type_container {
  display: -webkit-flex;
  display: flex;
  height: 2.60417vw;
  -webkit-align-items: center;
          align-items: center;
  font-family: 'Roboto', sans-serif;
  font-size: 1.04167vw;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.9);
  border-bottom: 0.5px solid rgba(0, 0, 0, 0.9);
  color: #FBC02D;
}

.post_publications_container {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  height: 2.60417vw;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  border-bottom: 0.5px solid rgba(0, 0, 0, 0.9);
}

.post_publications_container .publication_change {
  display: -webkit-flex;
  display: flex;
  height: 2.60417vw;
  -webkit-align-items: center;
          align-items: center;
  font-family: 'Roboto', sans-serif;
  font-size: 0.9375vw;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.9);
  cursor: pointer;
}

.post_publications_container .publication_view_label {
  display: -webkit-inline-flex;
  display: inline-flex;
  height: 1.82292vw;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  font-family: 'Roboto', sans-serif;
  font-size: 0.9375vw;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.9);
  padding-left: 0.52083vw;
  padding-right: 0.52083vw;
  margin-left: 0.52083vw;
  margin-right: 0.52083vw;
  background: rgba(0, 0, 0, 0.3);
  border-radius: 1.04167vw;
  cursor: pointer;
}

.headline_container {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  border-bottom: 0.5px solid rgba(0, 0, 0, 0.9);
  -webkit-justify-content: space-between;
          justify-content: space-between;
  width: 100%;
}

.headline_container .headline_input {
  width: 90%;
  height: 3.64583vw;
}

.headline_container .headline_count {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: flex-end;
          justify-content: flex-end;
  width: 10%;
  height: 3.64583vw;
  font-family: 'Roboto', sans-serif;
  font-size: 1.5625vw;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.4);
}

.headline_container textarea {
  display: -webkit-flex;
  display: flex;
  height: 90%;
  -webkit-align-items: center;
          align-items: center;
  font-family: 'Roboto', sans-serif;
  font-size: 1.5625vw;
  font-weight: 500;
  width: 100%;
  color: rgba(0, 0, 0, 0.4);
  border: none;
  outline: none;
  resize: none;
}

.summary_container {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  border-bottom: 0.5px solid rgba(0, 0, 0, 0.9);
  -webkit-justify-content: space-between;
          justify-content: space-between;
  width: 100%;
}

.summary_container .summary_input {
  width: 90%;
  height: 5.20833vw;
}

.summary_container .summary_count {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: flex-end;
          justify-content: flex-end;
  width: 10%;
  height: 5.20833vw;
  font-family: 'Roboto', sans-serif;
  font-size: 1.5625vw;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.4);
}

.summary_container textarea {
  display: -webkit-flex;
  display: flex;
  height: 90%;
  -webkit-align-items: center;
          align-items: center;
  font-family: 'Roboto', sans-serif;
  font-size: 1.30208vw;
  font-weight: 400;
  width: 100%;
  color: rgba(0, 0, 0, 0.4);
  border: none;
  outline: none;
  resize: none;
}

.content_and_chat_container {
  display: -webkit-flex;
  display: flex;
  width: 100%;
  -webkit-flex-direction: row;
          flex-direction: row;
  min-height: 36.45833vw;
  -webkit-justify-content: space-between;
          justify-content: space-between;
}

.editor_container {
  width: 70%;
}

.editor_container .ck {
  height: 31.25vw;
  font-family: 'Roboto', sans-serif;
  font-size: 1.04167vw;
  color: rgba(0, 0, 0, 0.9);
}

.editor_container .ck-content .image > figcaption {
  display: table-caption;
  caption-side: bottom;
  word-break: break-word;
  color: #333;
  background-color: #f7f7f7;
  text-align: left;
  padding: .6em;
  font-size: .75em;
  outline-offset: -1px;
}

.editor_container .editor_container_label {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-flex-direction: row;
          flex-direction: row;
  border-bottom: 0.5px solid rgba(0, 0, 0, 0.9);
}

.editor_container .editor_container_label .editor_label {
  display: -webkit-flex;
  display: flex;
  height: 2.60417vw;
  -webkit-align-items: center;
          align-items: center;
  font-family: 'Roboto', sans-serif;
  font-size: 1.04167vw;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.9);
  color: #FBC02D;
}

.editor_container .editor_container_label .editor_count {
  display: -webkit-flex;
  display: flex;
  height: 2.60417vw;
  -webkit-align-items: center;
          align-items: center;
  font-family: 'Roboto', sans-serif;
  font-size: 1.04167vw;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.9);
  color: #FBC02D;
}

.editor_container .editor_content {
  padding-top: 1.04167vw;
}

.chat_container {
  width: 28%;
}

.chat_container .chat_container_label {
  display: -webkit-flex;
  display: flex;
  height: 2.60417vw;
  -webkit-align-items: center;
          align-items: center;
  font-family: 'Roboto', sans-serif;
  font-size: 1.04167vw;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.9);
  border-bottom: 0.5px solid rgba(0, 0, 0, 0.9);
  color: #FBC02D;
  -webkit-justify-content: flex-end;
          justify-content: flex-end;
}

.chat_container .chat_window_container {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  height: 26.04167vw;
  overflow-y: scroll;
  overflow-x: hidden;
  -webkit-align-items: center;
          align-items: center;
}

.chat_head_container {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  width: 80%;
  background: #EEEEEE;
  height: -webkit-min-content;
  height: min-content;
  padding: 0.52083vw;
  border-radius: 1.04167vw;
  margin: 0.26042vw;
}

.chat_head_container .head_name {
  display: -webkit-flex;
  display: flex;
  height: 2.08333vw;
  width: 100%;
  -webkit-justify-content: flex-end;
          justify-content: flex-end;
  -webkit-align-items: center;
          align-items: center;
  font-family: 'Roboto', sans-serif;
  font-size: 1.04167vw;
  font-weight: 500;
  border-bottom: 0.5px solid rgba(0, 0, 0, 0.9);
}

.chat_head_container .message {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: flex-start;
          justify-content: flex-start;
  font-family: 'Roboto', sans-serif;
  font-size: 1.04167vw;
  font-weight: 300;
}

.featured_image_main_container {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  width: 100%;
  height: 5.20833vw;
  -webkit-align-items: center;
          align-items: center;
  border-bottom: 0.5px solid rgba(0, 0, 0, 0.9);
}

.featured_image_main_container .featured_label {
  display: -webkit-flex;
  display: flex;
  height: 2.60417vw;
  -webkit-align-items: center;
          align-items: center;
  font-family: 'Roboto', sans-serif;
  font-size: 1.04167vw;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.9);
  color: #FBC02D;
  width: 14%;
}

.featured_image_main_container .featured_image_url {
  width: 40%;
  display: -webkit-flex;
  display: flex;
  height: 2.60417vw;
  -webkit-align-items: center;
          align-items: center;
  font-family: 'Roboto', sans-serif;
  font-size: 1.04167vw;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.9);
  padding-left: 1.04167vw;
}

.featured_image_main_container .featured_upload_new {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  font-family: 'Roboto', sans-serif;
  font-size: 1.30208vw;
  font-weight: 500;
  color: white;
  border: none;
  background: #c2c2c2;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.24), 0px 0px 2px rgba(0, 0, 0, 0.12);
  border-radius: 0.52083vw;
  height: 3.125vw;
  width: 10.41667vw;
}

.featured_image {
  display: -webkit-flex;
  display: flex;
  height: 20.83333vw;
}

.featured_image img {
  width: -webkit-fill-available;
}

.prmpty_for_image_container {
  display: -webkit-flex;
  display: flex;
  width: 100%;
  -webkit-align-content: space-between;
          align-content: space-between;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-flex-direction: column;
          flex-direction: column;
}

.prmpty_for_image_container .prompt_label {
  display: -webkit-flex;
  display: flex;
  height: 5.20833vw;
  -webkit-align-items: center;
          align-items: center;
  width: 100%;
  font-family: 'Roboto', sans-serif;
  font-size: 1.04167vw;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.9);
  -webkit-justify-content: center;
          justify-content: center;
}

.prmpty_for_image_container .button_prompt_container {
  display: -webkit-flex;
  display: flex;
  height: 5.20833vw;
  width: 95%;
  -webkit-align-items: flex-end;
          align-items: flex-end;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-flex-direction: row;
          flex-direction: row;
  margin: auto;
}

.prmpty_for_image_container .button_prompt_container button {
  font-family: 'Roboto', sans-serif;
  font-size: 0.9375vw;
  width: 7.8125vw;
  font-weight: 500;
}

.custom_file_upload .custom_input_upload {
  width: 100%;
  height: 2.1875vw;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}

.custom_file_upload .custom_input_label {
  font-size: 0.9375vw;
  display: -webkit-flex;
  display: flex;
  line-height: 2.1875vw;
  font-family: 'Roboto', sans-serif;
  font-size: 0.9375vw;
  width: 7.8125vw;
  font-weight: 500;
}

.image_gallery_main_container {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  width: 100%;
}

.image_gallery_main_container .image_label {
  display: -webkit-flex;
  display: flex;
  height: 5.20833vw;
  -webkit-align-items: center;
          align-items: center;
  width: 100%;
  font-family: 'Roboto', sans-serif;
  font-size: 1.30208vw;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.9);
}

.image_gallery_main_container .image_container {
  display: -webkit-flex;
  display: flex;
  width: 100%;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
}

.image_container_single {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  height: 10.41667vw;
  width: 10.41667vw;
  margin: 0.52083vw;
}

.image_container_single img {
  display: -webkit-flex;
  display: flex;
  height: 7.8125vw;
  width: 10.41667vw;
}

.image_container_single .image_label {
  display: -webkit-flex;
  display: flex;
  height: 2.60417vw;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  width: 100%;
  font-family: 'Roboto', sans-serif;
  font-size: 1.30208vw;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.9);
}

.category_tags_container_one {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: space-between;
          justify-content: space-between;
}

.category_tags_container_one .category_tags_label {
  width: 20%;
  display: -webkit-flex;
  display: flex;
  height: 2.60417vw;
  -webkit-align-items: center;
          align-items: center;
  font-family: 'Roboto', sans-serif;
  font-size: 1.04167vw;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.9);
  color: #FBC02D;
}

.category_input {
  width: 80%;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  height: 2.60417vw;
}

.category_input textarea {
  display: -webkit-flex;
  display: flex;
  height: 90%;
  -webkit-align-items: center;
          align-items: center;
  font-family: 'Roboto', sans-serif;
  font-size: 1.04167vw;
  font-weight: 300;
  width: 100%;
  color: rgba(0, 0, 0, 0.8);
  border: none;
  outline: none;
  border-bottom: 0.5px solid rgba(0, 0, 0, 0.8);
  resize: none;
}

.category_tags_container_two {
  display: -webkit-flex;
  display: flex;
  width: 100%;
  height: 3.125vw;
  -webkit-align-items: center;
          align-items: center;
  border-bottom: 0.5px solid rgba(0, 0, 0, 0.8);
}

.category_tags_container_two .tags {
  display: -webkit-inline-flex;
  display: inline-flex;
  height: 1.82292vw;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  font-family: 'Roboto', sans-serif;
  font-size: 0.9375vw;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.9);
  padding-left: 0.52083vw;
  padding-right: 0.52083vw;
  margin-left: 0.52083vw;
  margin-right: 0.52083vw;
  background: rgba(0, 0, 0, 0.3);
  border-radius: 1.04167vw;
  cursor: pointer;
}

.post_logs_main_container {
  display: -webkit-flex;
  display: flex;
  width: 100%;
}

.post_logs_main_container .post_logs_label {
  width: 100%;
  display: -webkit-flex;
  display: flex;
  height: 2.60417vw;
  -webkit-align-items: center;
          align-items: center;
  font-family: 'Roboto', sans-serif;
  font-size: 1.30208vw;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.9);
  border-bottom: 0.5px solid rgba(0, 0, 0, 0.9);
  color: #FBC02D;
  -webkit-justify-content: flex-end;
          justify-content: flex-end;
  text-transform: capitalize;
}

.author_notes_container {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  width: 100%;
  border-bottom: 0.5px solid rgba(0, 0, 0, 0.9);
  height: 5.20833vw;
  -webkit-align-items: center;
          align-items: center;
}

.author_notes_container .author_label {
  width: 20%;
  display: -webkit-flex;
  display: flex;
  height: 5.20833vw;
  -webkit-align-items: center;
          align-items: center;
  font-family: 'Roboto', sans-serif;
  font-size: 1.30208vw;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.9);
  color: #FBC02D;
  text-transform: capitalize;
}

.author_notes_container textarea {
  display: -webkit-flex;
  display: flex;
  height: 90%;
  -webkit-align-items: center;
          align-items: center;
  font-family: 'Roboto', sans-serif;
  font-size: 0.9375vw;
  font-weight: 400;
  width: 100%;
  color: rgba(0, 0, 0, 0.8);
  border: none;
  outline: none;
  resize: none;
}

.sub_author_main_container {
  display: -webkit-flex;
  display: flex;
  width: 100%;
  border-bottom: 0.5px solid rgba(0, 0, 0, 0.9);
  -webkit-flex-direction: row;
          flex-direction: row;
}

.sub_author_main_container .sub_author_list {
  display: -webkit-flex;
  display: flex;
  width: 90%;
}

.sub_author_main_container .add_author {
  display: -webkit-flex;
  display: flex;
  width: 10%;
  display: flex;
  height: 2.60417vw;
  -webkit-align-items: center;
          align-items: center;
  font-family: 'Roboto', sans-serif;
  font-size: 0.98958vw;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.9);
  text-transform: capitalize;
  -webkit-justify-content: flex-end;
          justify-content: flex-end;
  cursor: pointer;
}

.sub_author {
  display: -webkit-inline-flex;
  display: inline-flex;
  height: 1.82292vw;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  font-family: 'Roboto', sans-serif;
  font-size: 0.9375vw;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.9);
  padding-left: 0.52083vw;
  padding-right: 0.52083vw;
  margin-left: 0.52083vw;
  margin-right: 0.52083vw;
  background: rgba(0, 0, 0, 0.3);
  border-radius: 1.04167vw;
  cursor: pointer;
}

.sub_author_main_container {
  display: -webkit-flex;
  display: flex;
  height: 2.60417vw;
  -webkit-align-items: center;
          align-items: center;
  -webkit-flex-direction: row;
          flex-direction: row;
}

.preview_container_modal .ck.ck-editor__main > .ck-editor__editable:not(.ck-focused) {
  border-color: white !important;
  font-family: 'Roboto', sans-serif;
  font-size: 1.04167vw;
  color: rgba(0, 0, 0, 0.9);
}

.preview_container_modal .ck.ck-toolbar {
  border: none !important;
  font-family: 'Roboto', sans-serif;
  font-size: 1.04167vw;
  color: rgba(0, 0, 0, 0.9);
}

.headline_display {
  font-family: 'Roboto', sans-serif;
  font-size: 2.08333vw;
  display: -webkit-flex;
  display: flex;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.9);
}

.summary_display {
  font-family: 'Roboto', sans-serif;
  font-size: 1.5625vw;
  font-weight: 300;
  color: rgba(0, 0, 0, 0.9);
}

.url_of_association_main_container {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  width: 100%;
  height: 3.125vw;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  border-bottom: 0.5px solid rgba(0, 0, 0, 0.8);
}

.url_of_association_main_container .url_label {
  display: -webkit-flex;
  display: flex;
  height: 3.125vw;
  -webkit-align-items: center;
          align-items: center;
  width: 20%;
  font-family: 'Roboto', sans-serif;
  font-size: 1.04167vw;
  font-weight: 500;
  color: #FBC02D;
}

.url_of_association_main_container .url_input {
  display: -webkit-flex;
  display: flex;
  height: 3.125vw;
  -webkit-align-items: center;
          align-items: center;
  width: 90%;
}

.url_of_association_main_container .url_input textarea {
  display: -webkit-flex;
  display: flex;
  height: 80%;
  -webkit-align-items: center;
          align-items: center;
  font-family: 'Roboto', sans-serif;
  font-size: 1.04167vw;
  font-weight: 300;
  width: 100%;
  color: rgba(0, 0, 0, 0.8);
  border: none;
  outline: none;
  border-bottom: 0.5px solid rgba(0, 0, 0, 0.8);
  resize: none;
}

.event_dates_container {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  width: 100%;
  height: 2.60417vw;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: space-between;
          justify-content: space-between;
}

.event_dates_container .event_label {
  display: -webkit-flex;
  display: flex;
  height: 2.60417vw;
  -webkit-align-items: center;
          align-items: center;
  width: 20%;
  font-family: 'Roboto', sans-serif;
  font-size: 1.04167vw;
  font-weight: 500;
  color: #FBC02D;
}

.event_dates_container .event_input {
  display: -webkit-flex;
  display: flex;
  height: 2.60417vw;
  -webkit-align-items: center;
          align-items: center;
  width: 30%;
}

.event_dates_container .event_input input {
  display: -webkit-flex;
  display: flex;
  height: 90%;
  -webkit-align-items: center;
          align-items: center;
  font-family: 'Roboto', sans-serif;
  font-size: 1.04167vw;
  font-weight: 300;
  width: 100%;
  color: rgba(0, 0, 0, 0.8);
  border: none;
  outline: none;
  border-bottom: 0.5px solid rgba(0, 0, 0, 0.8);
  resize: none;
}

.select_editor_pool_main_container {
  display: -webkit-flex;
  display: flex;
  width: 100%;
  height: 15.625vw;
  -webkit-align-content: space-between;
          align-content: space-between;
  -webkit-flex-direction: column;
          flex-direction: column;
}

.select_editor_pool_main_container .button_container {
  width: 90%;
  margin: auto;
}

.select_editor_pool_main_container .select_editor_label {
  display: -webkit-flex;
  display: flex;
  height: 5.20833vw;
  -webkit-align-items: center;
          align-items: center;
  width: 100%;
  font-family: 'Roboto', sans-serif;
  font-size: 1.04167vw;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.9);
  -webkit-justify-content: center;
          justify-content: center;
}

.select_editor_pool_main_container .button_container_editor {
  display: -webkit-flex;
  display: flex;
  height: 10.41667vw;
  width: 95%;
  -webkit-align-items: flex-end;
          align-items: flex-end;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-flex-direction: row;
          flex-direction: row;
  margin: auto;
}

.select_editor_pool_main_container .button_container_editor button {
  font-family: 'Roboto', sans-serif;
  font-size: 0.9375vw;
  font-weight: 500;
}

.select_editor_label {
  display: -webkit-flex;
  display: flex;
  height: 4.16667vw;
  -webkit-align-items: center;
          align-items: center;
  width: 100%;
  font-family: 'Roboto', sans-serif;
  font-size: 1.30208vw;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.9);
  -webkit-justify-content: center;
          justify-content: center;
}

.select_editor_input {
  display: -webkit-flex;
  display: flex;
  height: 4.16667vw;
  -webkit-align-items: center;
          align-items: center;
  width: 100%;
  font-family: 'Roboto', sans-serif;
  font-size: 1.30208vw;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.9);
  -webkit-justify-content: center;
          justify-content: center;
}

.select_editor_input select {
  display: -webkit-flex;
  display: flex;
  font-family: 'Roboto', sans-serif;
  font-size: 0.9375vw;
  font-weight: 500;
  width: 90%;
  margin: auto;
}

.select_editor_input option {
  display: -webkit-flex;
  display: flex;
  font-family: 'Roboto', sans-serif;
  font-size: 0.9375vw;
  font-weight: 300;
  margin: auto;
}

.publish_form_container {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  padding: 0.26042vw;
  width: 100%;
}

.publist_label {
  display: -webkit-flex;
  display: flex;
  height: 2.60417vw;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: flex-start;
          justify-content: flex-start;
  width: 100%;
  font-family: 'Roboto', sans-serif;
  font-size: 1.30208vw;
  color: rgba(0, 0, 0, 0.9);
  border-bottom: 0.5px solid rgba(0, 0, 0, 0.9);
}

.publish_label_date {
  display: -webkit-flex;
  display: flex;
  height: 1.5625vw;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: flex-start;
          justify-content: flex-start;
  width: 100%;
  font-family: 'Roboto', sans-serif;
  font-size: 0.83333vw;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.9);
}

.publish_form_container .post_type_form_container {
  padding: 0;
  height: 100%;
}

.post_featured_subscription {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  width: 100;
  border-top: 0.5px solid rgba(0, 0, 0, 0.9);
  border-bottom: 0.5px solid rgba(0, 0, 0, 0.9);
}

.post_featured_subscription .post_featued_label {
  display: -webkit-flex;
  display: flex;
  height: 2.60417vw;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: flex-start;
          justify-content: flex-start;
  width: 90%;
  font-family: 'Roboto', sans-serif;
  font-size: 0.83333vw;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.9);
}

.post_featured_subscription .post_featued_input {
  display: -webkit-flex;
  display: flex;
  height: 2.60417vw;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: flex-end;
          justify-content: flex-end;
  width: 10%;
}

.main_container_category_and_others {
  display: -webkit-flex;
  display: flex;
  width: 100%;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-justify-content: space-between;
          justify-content: space-between;
}

.category_author_notes {
  width: 70%;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
}

.post_logs {
  width: 28%;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
}

.author_buttons {
  display: -webkit-flex;
  display: flex;
  width: 100%;
  height: 4.16667vw;
  -webkit-align-items: center;
          align-items: center;
  border-top: 0.5px solid rgba(0, 0, 0, 0.9);
}

.preview_button_position {
  display: -webkit-flex;
  display: flex;
  width: 60%;
}

.preview_button_position .button_yellow_prevew {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  font-family: 'Roboto', sans-serif;
  font-size: 1.30208vw;
  font-weight: 500;
  color: white;
  background: #FBC02D;
  border: none;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.24), 0px 0px 2px rgba(0, 0, 0, 0.12);
  height: 3.125vw;
  width: 7.8125vw;
  border-radius: 0.52083vw;
}

.close_button_position {
  display: -webkit-flex;
  display: flex;
  width: 20%;
}

.close_button_position .button_yellow_close {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  font-family: 'Roboto', sans-serif;
  font-size: 1.30208vw;
  font-weight: 500;
  color: white;
  background: #FBC02D;
  border: none;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.24), 0px 0px 2px rgba(0, 0, 0, 0.12);
  height: 3.125vw;
  width: 7.8125vw;
  border-radius: 0.52083vw;
}

.submit_button_position {
  display: -webkit-flex;
  display: flex;
  width: 20%;
}

.send_back_to_author {
  display: -webkit-flex;
  display: flex;
  width: 100%;
  height: 2.60417vw;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: flex-end;
          justify-content: flex-end;
}

.send_back_to_author .send_back_lable {
  font-family: 'Roboto', sans-serif;
  font-size: 1.04167vw;
  font-weight: 500;
  cursor: pointer;
  color: #FBC02D;
}

.methodology_label_new {
  display: -webkit-flex;
  display: flex;
  height: 2.60417vw;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-align-items: center;
          align-items: center;
  border-bottom: 0.5px solid rgba(0, 0, 0, 0.9);
}

.methodology_label_new .method_name {
  display: -webkit-flex;
  display: flex;
  height: 2.60417vw;
  -webkit-align-items: center;
          align-items: center;
  font-family: 'Roboto', sans-serif;
  font-size: 1.30208vw;
  font-weight: 400;
  color: #FBC02D;
}

.methodology_label_new .add_method_label {
  display: -webkit-flex;
  display: flex;
  height: 2.60417vw;
  -webkit-align-items: center;
          align-items: center;
  font-family: 'Roboto', sans-serif;
  font-size: 1.30208vw;
  font-weight: 400;
  color: #FBC02D;
  cursor: pointer;
}

.add_methodology_label {
  display: -webkit-flex;
  display: flex;
  height: 3.125vw;
  -webkit-align-items: center;
          align-items: center;
  font-family: 'Roboto', sans-serif;
  font-size: 1.30208vw;
  font-weight: 400;
  color: #FBC02D;
  border-bottom: 0.5px solid rgba(0, 0, 0, 0.9);
}

.parent_category_container {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
}

.parent_category_container .method_add_label {
  display: -webkit-flex;
  display: flex;
  height: 2.60417vw;
  -webkit-align-items: center;
          align-items: center;
  font-family: 'Roboto', sans-serif;
  font-size: 0.9375vw;
  font-weight: 700;
  color: rgba(0, 0, 0, 0.5);
}

.method_type_container {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
}

.method_type_container .method_add_label {
  display: -webkit-flex;
  display: flex;
  height: 2.60417vw;
  -webkit-align-items: center;
          align-items: center;
  font-family: 'Roboto', sans-serif;
  font-size: 0.9375vw;
  font-weight: 700;
  color: rgba(0, 0, 0, 0.5);
}

.method_type_container .select_input {
  display: -webkit-flex;
  display: flex;
  width: 100%;
  height: 2.08333vw;
  -webkit-align-items: center;
          align-items: center;
  border: none;
  outline: none;
  font-family: 'Roboto', sans-serif;
  font-size: 0.9375vw;
  font-weight: 300px;
}

.method_name_container {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
}

.method_name_container .method_add_label {
  display: -webkit-flex;
  display: flex;
  height: 2.60417vw;
  -webkit-align-items: center;
          align-items: center;
  font-family: 'Roboto', sans-serif;
  font-size: 0.9375vw;
  font-weight: 700;
  color: rgba(0, 0, 0, 0.5);
}

.method_add_button_container {
  display: -webkit-flex;
  display: flex;
  width: 100%;
  -webkit-justify-content: flex-end;
          justify-content: flex-end;
}

.method_add_button_container .add_buttons {
  display: -webkit-flex;
  display: flex;
  height: 2.60417vw;
  width: 6.77083vw;
  background: #FBC02D;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  font-family: 'Roboto', sans-serif;
  font-size: 1.04167vw;
  font-weight: 500;
  color: white;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.24), 0px 0px 2px rgba(0, 0, 0, 0.12);
  border-radius: 0.52083vw;
  cursor: pointer;
}

.method_add_button_container .add_buttons:hover {
  box-shadow: none;
}

.main_block_container {
  display: -webkit-flex;
  display: flex;
  margin-top: 1.5625vw;
  margin-bottom: 1.5625vw;
  width: 100%;
  -webkit-flex-direction: column;
          flex-direction: column;
}

.main_block_container .methodolfy_label {
  display: -webkit-flex;
  display: flex;
  height: 2.60417vw;
  -webkit-align-items: center;
          align-items: center;
  font-family: 'Roboto', sans-serif;
  font-size: 1.04167vw;
  font-weight: 400;
  color: #FBC02D;
  border-bottom: 0.5px solid rgba(0, 0, 0, 0.9);
}

.main_block_container .method_dispaly_container {
  display: -webkit-flex;
  display: flex;
  width: 100%;
  height: 15.625vw;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  overflow: auto;
  overflow-x: hidden;
}

.methodology_display_container {
  display: -webkit-inline-flex;
  display: inline-flex;
  padding-left: 1.04167vw;
  padding-right: 1.04167vw;
  margin: 0.26042vw;
  border-radius: 0.78125vw;
  background: rgba(0, 0, 0, 0.2);
  height: 1.5625vw;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  font-family: 'Roboto', sans-serif;
  font-size: 0.9375vw;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.7);
  min-width: 10.41667vw;
  cursor: pointer;
}

.delete_container {
  display: -webkit-flex;
  display: flex;
  height: 1.5625vw;
  -webkit-align-items: center;
          align-items: center;
}

.delete_label {
  display: -webkit-flex;
  display: flex;
  height: 1.5625vw;
  -webkit-align-items: center;
          align-items: center;
  cursor: pointer;
  font-family: 'Roboto', sans-serif;
  font-size: 0.9375vw;
  font-weight: 500;
  color: #F44336;
}

.add_new_source_label_container {
  display: -webkit-flex;
  display: flex;
  width: 100%;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: flex-end;
          justify-content: flex-end;
  border-bottom: 0.5px solid rgba(0, 0, 0, 0.9);
}

.add_new_source_label {
  display: -webkit-flex;
  display: flex;
  height: 2.60417vw;
  font-family: 'Roboto', sans-serif;
  font-size: 1.30208vw;
  font-weight: 500;
  -webkit-align-items: center;
          align-items: center;
  cursor: pointer;
}

.add_company {
  border: 1px solid;
  padding: 0 10px;
  border-radius: 5px;
  background-color: #FBC02D;
}

.source_companys_title {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  font-size: 2.08333vw;
  margin: 2.08333vw 0px 3.125vw 0px;
  font-weight: 500;
}

.add_source_company_container {
  display: -webkit-flex;
  display: flex;
  width: 100%;
  -webkit-flex-direction: column;
          flex-direction: column;
}

.source_head {
  display: -webkit-flex;
  display: flex;
  height: 2.60417vw;
  font-family: 'Roboto', sans-serif;
  font-size: 1.30208vw;
  font-weight: 500;
  color: #FBC02D;
  -webkit-align-items: center;
          align-items: center;
  border-bottom: 0.5px solid rgba(0, 0, 0, 0.9);
}

.source_input_container {
  display: -webkit-flex;
  display: flex;
  width: 100%;
  -webkit-flex-direction: column;
          flex-direction: column;
  margin-top: 0.52083vw;
  margin-bottom: 0.52083vw;
}

.source_input_label {
  display: -webkit-flex;
  display: flex;
  height: 2.08333vw;
  font-family: 'Roboto', sans-serif;
  font-size: 0.9375vw;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.9);
  -webkit-align-items: center;
          align-items: center;
}

.source_button_container {
  display: -webkit-flex;
  display: flex;
  width: 100%;
  -webkit-flex-direction: column;
          flex-direction: column;
  margin-top: 0.52083vw;
  margin-bottom: 0.52083vw;
  -webkit-align-items: flex-end;
          align-items: flex-end;
  -webkit-justify-content: flex-end;
          justify-content: flex-end;
}

.display_area_main_container {
  display: -webkit-flex;
  display: flex;
  height: 20.83333vw;
  width: 100%;
  overflow: auto;
  overflow-x: hidden;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  margin-top: 1.5625vw;
  margin-bottom: 1.5625vw;
}

.source_block_main {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  margin: 1.04167vw;
  padding: 1.04167vw;
  border-radius: 10px;
  box-shadow: 0px 0px 20px -5px rgba(150, 150, 150, 0.57);
}

.source_block_main .source_name {
  border-radius: 0.52083vw;
  display: -webkit-flex;
  display: flex;
  font-family: 'Roboto', sans-serif;
  font-size: 1.04167vw;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.5);
  width: 70%;
  height: 3.125vw;
  -webkit-align-items: center;
          align-items: center;
  padding-left: 0.52083vw;
}

.source_block_main .source_button {
  border-radius: 0.52083vw;
  width: 30%;
  display: -webkit-flex;
  display: flex;
  background: #00C950;
  font-family: 'Roboto', sans-serif;
  font-size: 1.04167vw;
  font-weight: 500;
  color: white;
  height: 2.91667vw;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  cursor: pointer;
}

.add_source {
  border: 1px solid;
  padding: 0 10px;
  border-radius: 5px;
  background-color: #FBC02D;
}

.about_company_container {
  display: -webkit-flex;
  display: flex;
  height: 2.60417vw;
  font-family: 'Roboto', sans-serif;
  font-size: 1.04167vw;
  font-weight: 500;
  color: #FBC02D;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  border-bottom: 0.5px solid rgba(0, 0, 0, 0.9);
}

.about_company_container .company_name {
  display: -webkit-flex;
  display: flex;
  height: 2.60417vw;
  -webkit-align-items: center;
          align-items: center;
  font-family: 'Roboto', sans-serif;
  font-size: 1.04167vw;
  font-weight: 500;
  color: #FBC02D;
}

.about_company_container .edit_button {
  display: -webkit-flex;
  display: flex;
  height: 2.60417vw;
  -webkit-align-items: center;
          align-items: center;
  font-family: 'Roboto', sans-serif;
  font-size: 1.04167vw;
  font-weight: 700;
  color: #F44336;
  cursor: pointer;
}

.active_archive_nav_container {
  display: -webkit-flex;
  display: flex;
  height: 2.60417vw;
  -webkit-align-items: center;
          align-items: center;
  border-bottom: 0.5px solid rgba(0, 0, 0, 0.9);
}

.active_archive_nav_container .nav_active_archive {
  -webkit-align-items: center;
          align-items: center;
  display: inline-block;
  padding-left: 0.78125vw;
  padding-right: 0.78125vw;
  font-family: 'Roboto', sans-serif;
  font-size: 1.30208vw;
  cursor: pointer;
}

.active_archive_nav_container .nav_active_archive_active {
  display: inline-block;
  -webkit-align-items: center;
          align-items: center;
  padding-left: 0.78125vw;
  padding-right: 0.78125vw;
  font-family: 'Roboto', sans-serif;
  font-size: 1.30208vw;
  background: rgba(196, 196, 196, 0.5);
  border-radius: 1.04167vw;
  cursor: pointer;
}

.add_source_person_container {
  display: -webkit-flex;
  display: flex;
  height: 2.60417vw;
  width: 100%;
  -webkit-align-items: center;
          align-items: center;
  border-bottom: 0.5px solid rgba(0, 0, 0, 0.9);
  -webkit-justify-content: flex-end;
          justify-content: flex-end;
}

.add_source_person_container .add_source_label {
  display: -webkit-flex;
  display: flex;
  height: 2.60417vw;
  font-family: 'Roboto', sans-serif;
  font-size: 1.30208vw;
  font-weight: 500;
  -webkit-align-items: center;
          align-items: center;
  cursor: pointer;
}

.trade_log_view_container {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  width: 100%;
  height: 15.625vw;
  overflow: auto;
  overflow-x: hidden;
}

.render_chip_container {
  display: -webkit-flex;
  display: flex;
  width: 95%;
  -webkit-flex-direction: row;
          flex-direction: row;
  margin: 0.52083vw;
  -webkit-align-items: center;
          align-items: center;
  padding-left: 0.52083vw;
  padding-right: 0.52083vw;
  background: rgba(0, 0, 0, 0.5);
  border-radius: 0.78125vw;
}

.chip_name {
  display: -webkit-flex;
  display: flex;
  height: 2.60417vw;
  -webkit-align-items: center;
          align-items: center;
  font-family: 'Roboto', sans-serif;
  font-size: 0.83333vw;
  font-weight: 500;
  width: 100%;
}

.remove_chip {
  display: -webkit-flex;
  display: flex;
  height: 1.5625vw;
  -webkit-align-items: center;
          align-items: center;
  width: 20%;
  align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  cursor: pointer;
}

.add_new_prices_label_container {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  width: 100%;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  border-bottom: 0.5px solid rgba(0, 0, 0, 0.9);
}

.add_new_prices_label_container .add_new_prices_name {
  display: -webkit-flex;
  display: flex;
  height: 2.60417vw;
  -webkit-align-items: center;
          align-items: center;
  font-family: 'Roboto', sans-serif;
  font-size: 1.04167vw;
  font-weight: 500;
  color: #FBC02D;
}

.add_new_prices_label_container .add_new_prices_label {
  display: -webkit-flex;
  display: flex;
  height: 2.60417vw;
  -webkit-align-items: center;
          align-items: center;
  font-family: 'Roboto', sans-serif;
  font-size: 1.04167vw;
  font-weight: 500;
  color: #FBC02D;
  cursor: pointer;
}

.form_one_main_container {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  width: 100%;
}

.metal_grade_form_container {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  width: 50%;
}

.metal_grade_container_label {
  display: -webkit-flex;
  display: flex;
  height: 2.60417vw;
  -webkit-align-items: center;
          align-items: center;
  font-family: 'Roboto', sans-serif;
  font-size: 1.04167vw;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.9);
}

.price_display_area_container {
  display: -webkit-flex;
  display: flex;
  width: 100%;
  -webkit-flex-direction: column;
          flex-direction: column;
  height: 31.25vw;
  overflow: auto;
  overflow-x: hidden;
  overflow-y: auto;
  margin-top: 0.52083vw;
  margin-bottom: 0.52083vw;
}

.adding_new_price_label {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  width: 100%;
}

.adding_new_price_label .dpc_number {
  display: -webkit-flex;
  display: flex;
  height: 2.60417vw;
  width: 100%;
  -webkit-align-items: center;
          align-items: center;
  font-family: 'Roboto', sans-serif;
  font-size: 1.04167vw;
  font-weight: 500;
  color: #FBC02D;
  border-bottom: 0.5px solid rgba(0, 0, 0, 0.9);
}

.form_one_main_container {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  width: 100%;
  -webkit-justify-content: space-between;
          justify-content: space-between;
}

.form_part {
  display: -webkit-flex;
  display: flex;
  width: 49%;
  -webkit-flex-direction: column;
          flex-direction: column;
}

.border_vertical {
  width: 0.10417vw;
  border-left: 0.5px solid rgba(0, 0, 0, 0.9);
}

.form_one_error_container {
  margin-top: 1.04167vw;
  display: -webkit-flex;
  display: flex;
  height: 100%;
  min-height: 2.60417vw;
  width: 100%;
  -webkit-flex-direction: column;
          flex-direction: column;
  border-bottom: 0.5px solid rgba(0, 0, 0, 0.9);
}

.form_one_button_container {
  display: -webkit-flex;
  display: flex;
  height: 2.60417vw;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: flex-end;
          justify-content: flex-end;
  border-bottom: 0.5px solid rgba(0, 0, 0, 0.9);
}

.next_button {
  font-family: 'Roboto', sans-serif;
  font-size: 1.30208vw;
  font-weight: 500;
  color: #FBC02D;
  cursor: pointer;
}

.form_button_container {
  display: -webkit-flex;
  display: flex;
  height: 2.60417vw;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  border-bottom: 0.5px solid rgba(0, 0, 0, 0.9);
}

.container_flex {
  display: -webkit-flex;
  display: flex;
  width: 100%;
  -webkit-justify-content: space-between;
          justify-content: space-between;
}

.render_prices_main_container {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  width: 100%;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  border-top: 0.5px solid rgba(0, 0, 0, 0.9);
}

.render_prices_label {
  display: -webkit-flex;
  display: flex;
  width: 80%;
  height: 3.125vw;
  -webkit-align-items: center;
          align-items: center;
  font-family: 'Roboto', sans-serif;
  font-size: 1.30208vw;
  font-weight: 500;
}

.render_class_manage_button {
  display: -webkit-flex;
  display: flex;
  width: 10%;
  height: 2.34375vw;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  color: white;
  background: #00C950;
  font-family: 'Roboto', sans-serif;
  font-size: 0.9375vw;
  font-weight: 500;
  border-bottom-left-radius: 0.52083vw;
  border-bottom-right-radius: 0.52083vw;
  cursor: pointer;
}

.display_dpc_main_container {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  width: 100%;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  border-bottom: 0.5px solid rgba(0, 0, 0, 0.9);
}

.dpc_label {
  display: -webkit-flex;
  display: flex;
  font-family: 'Roboto', sans-serif;
  font-size: 1.30208vw;
  height: 2.60417vw;
  -webkit-align-items: center;
          align-items: center;
  font-weight: 500;
  color: #FBC02D;
  cursor: pointer;
}

.edit_label {
  display: -webkit-flex;
  display: flex;
  font-family: 'Roboto', sans-serif;
  font-size: 1.30208vw;
  font-weight: 500;
  height: 2.60417vw;
  -webkit-align-items: center;
          align-items: center;
  color: #F44336;
  cursor: pointer;
}

.price_label_name_display {
  display: -webkit-flex;
  display: flex;
  height: 2.60417vw;
  width: 100%;
  font-family: 'Roboto', sans-serif;
  -webkit-align-items: center;
          align-items: center;
  font-size: 1.04167vw;
  font-weight: 500;
  color: #FBC02D;
  border-bottom: 0.02604vw solid rgba(0, 0, 0, 0.9);
}

.add_new_price_dpc_number_main_container {
  display: -webkit-flex;
  display: flex;
  height: 2.60417vw;
  width: 100%;
  -webkit-align-items: center;
          align-items: center;
  border-bottom: 0.5px solid rgba(0, 0, 0, 0.9);
}

.add_new_price_dpc_number_main_container .add_new_price_dpc_number {
  display: -webkit-flex;
  display: flex;
  font-family: 'Roboto', sans-serif;
  font-size: 1.30208vw;
  font-weight: 500;
  color: #FBC02D;
  padding-left: 0.52083vw;
}

.add_new_dpc_input_main_container {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  width: 100%;
}

.single_section_container {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  width: 49%;
}

.border_in_between_container {
  display: -webkit-flex;
  display: flex;
  height: auto;
  width: 0.10417vw;
  border-left: 0.5px solid black;
}

.metal_category_price_section_main_container {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  widows: 100%;
}

.label_name_add_prices {
  display: -webkit-flex;
  display: flex;
  width: 100%;
  height: 2.60417vw;
  -webkit-align-items: center;
          align-items: center;
  font-family: 'Roboto', sans-serif;
  font-size: 1.04167vw;
  font-weight: 700;
  color: rgba(0, 0, 0, 0.74);
  padding-left: 0.52083vw;
}

.add_prices_input_container {
  display: -webkit-flex;
  display: flex;
  width: 100%;
  height: 2.60417vw;
}

.error_contaner {
  display: -webkit-flex;
  display: flex;
  height: 1.04167vw;
  width: 100%;
  font-family: 'Roboto', sans-serif;
  font-size: 0.72917vw;
  font-weight: 700;
  color: red;
}

.on_boarded_organisation_main_controller {
  display: -webkit-flex;
  display: flex;
  height: 2.60417vw;
  width: 100%;
  font-family: 'Roboto', sans-serif;
  -webkit-align-items: center;
          align-items: center;
  font-size: 1.04167vw;
  font-weight: 500;
  color: #FBC02D;
  border-bottom: 0.02604vw solid rgba(0, 0, 0, 0.9);
}

.organisation_List_display_main_pabel {
  display: -webkit-flex;
  display: flex;
  width: 100%;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
}

.single_organisation_displa_unit {
  display: -webkit-flex;
  display: flex;
  height: 4.16667vw;
  margin: 0.26042vw;
  width: 24%;
  border-bottom: 0.02604vw solid rgba(0, 0, 0, 0.9);
}

.organisation_name_and_details_section {
  display: -webkit-flex;
  display: flex;
  width: 70%;
  -webkit-flex-direction: column;
          flex-direction: column;
}

.organisation_name {
  display: -webkit-flex;
  display: flex;
  width: 100%;
  height: 1.97917vw;
  font-family: 'Roboto', sans-serif;
  -webkit-align-items: center;
          align-items: center;
  font-size: 1.5625vw;
  padding-left: 1.04167vw;
  font-weight: 500px;
  color: rgba(0, 0, 0, 0.54);
  align-items: center;
}

.organisation_type {
  display: -webkit-flex;
  display: flex;
  width: 100%;
  height: 1.97917vw;
  font-family: 'Roboto', sans-serif;
  -webkit-align-items: center;
          align-items: center;
  font-size: 1.04167vw;
  padding-left: 1.04167vw;
  font-weight: 200px;
  color: rgba(0, 0, 0, 0.54);
  align-items: center;
}

.button_section {
  display: -webkit-flex;
  display: flex;
  height: 4.16667vw;
  width: 30%;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
}

.no_data_container {
  display: -webkit-flex;
  display: flex;
  height: 20.83333vw;
  width: 100%;
  -webkit-justify-content: center;
          justify-content: center;
  font-family: 'Roboto', sans-serif;
  -webkit-align-items: center;
          align-items: center;
  font-size: 1.04167vw;
  font-weight: 500;
  color: #FBC02D;
}

.user_list_container {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
}

.download-container {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: flex-end;
          justify-content: flex-end;
}

.download-data-btn {
  cursor: pointer;
  border: 1px solid #1a202c;
  padding: 4px;
  min-width: 120px;
  max-width: 120px;
  background: transparent;
  transition: all 0.1s ease-in;
  text-align: center;
}

.download-data-btn:focus, .download-data-btn:active {
  background-color: #FBC02D;
  border: 2px solid #1a202c;
}

.download-data-btn:hover {
  background-color: #FBC02D;
}

.user_container-outer {
  min-height: 26.04167vw;
}

.user_container {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
}

.user_container .users_name {
  display: -webkit-flex;
  display: flex;
}

.user_container .users_name .user-name {
  font-size: 1.35417vw;
  margin-right: 1.04167vw;
}

.user-outer-container {
  padding: 1.04167vw;
  border: 0.1px solid;
  width: 32%;
  margin: 1.30208vw 0vw;
  border: 1px solid #000;
}

.manage_user {
  color: #000;
  background-color: #FBC02D;
  border-color: #FBC02D;
  display: inline-block;
  font-weight: 400;
  color: #212529;
  text-align: center;
  vertical-align: middle;
  -webkit-user-select: none;
  -ms-user-select: none;
       user-select: none;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  cursor: pointer;
}

.manage_user:hover {
  color: #000;
  background-color: #FBC02D;
  border-color: #fbc12dcb;
  cursor: pointer;
}

.archive-containe {
  display: -webkit-flex;
  display: flex;
}

.archive-dropdown {
  display: -webkit-flex;
  display: flex;
  background-color: #fbc02d;
  color: #000;
  font-family: 'Roboto', sans-serif;
  font-size: 0.9375vw;
  font-weight: 500;
  width: auto;
  padding: 0vw 1.04167vw;
  height: 3.125vw;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  margin: 0.52083vw 0;
  border-radius: 0.26042vw;
  cursor: pointer;
  margin-right: 0.78125vw;
}

.update_organisation_main_controller {
  display: -webkit-flex;
  display: flex;
  height: 2.60417vw;
  border-bottom: 0.5px solid rgba(0, 0, 0, 0.9);
  width: 100%;
}

.organisation_name_holder {
  display: -webkit-flex;
  display: flex;
  height: 2.60417vw;
  width: 50%;
  font-family: 'Roboto', sans-serif;
  -webkit-align-items: center;
          align-items: center;
  font-size: 1.5625vw;
  font-weight: 400;
  color: #FBC02D;
}

.organisation_name_holders {
  display: -webkit-flex;
  display: flex;
  width: 100%;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-justify-content: space-between;
          justify-content: space-between;
}

.organisation_admin_names_holders {
  display: -webkit-flex;
  display: flex;
  width: 28%;
  -webkit-flex-direction: column;
          flex-direction: column;
}

.organisation_member_names_holders {
  display: -webkit-flex;
  display: flex;
  width: 70%;
}

.member_headline {
  display: -webkit-flex;
  display: flex;
  height: 2.60417vw;
  width: 100%;
  font-family: 'Roboto', sans-serif;
  -webkit-align-items: center;
          align-items: center;
  font-size: 1.04167vw;
  font-weight: 400;
  color: #FBC02D;
  border-bottom: 0.5px solid rgba(0, 0, 0, 0.9);
}

.admin_name_container {
  display: -webkit-flex;
  display: flex;
  width: 100%;
}

.names {
  display: -webkit-flex;
  display: flex;
  height: 2.60417vw;
  width: 70%;
  font-family: 'Roboto', sans-serif;
  -webkit-align-items: center;
          align-items: center;
  font-size: 1.04167vw;
  font-weight: 400;
  color: grey;
  border-bottom: 0.5px solid rgba(0, 0, 0, 0.9);
}

.archive_active_button {
  display: -webkit-flex;
  display: flex;
  height: 2.60417vw;
  width: 30%;
  font-family: 'Roboto', sans-serif;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: flex-end;
          justify-content: flex-end;
  font-size: 1.04167vw;
  font-weight: 500;
  color: #F44336;
  border-bottom: 0.5px solid rgba(0, 0, 0, 0.9);
  cursor: pointer;
}

.referral_user_containe {
  border: 2px solid;
  margin-top: 0.52083vw;
}

.sort-container {
  margin: 1.04167vw 0vw;
  width: 50%;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  margin-left: auto;
}

.sort-container .date-input {
  width: 15.625vw;
}

.sort-container .sort-btn {
  width: 7.8125vw;
}

.super_admin_master_upload_area {
  display: -webkit-flex;
  display: flex;
  width: 100%;
  height: 2.60417vw;
  margin-top: 2.60417vw;
  margin-bottom: 2.60417vw;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  border-bottom: 0.5px solid rgba(255, 0, 0, 0.9);
  border-top: 0.5px solid rgba(255, 0, 0, 0.9);
}

.super_admin_master_upload_area .super_admin_access_area {
  font-family: 'Roboto', sans-serif;
  font-size: 1.30208vw;
  font-weight: 500;
  height: 2.60417vw;
  -webkit-align-items: center;
          align-items: center;
  color: #F44336;
  display: -webkit-flex;
  display: flex;
}

.vwa_master_upload_main_container {
  display: -webkit-flex;
  display: flex;
  width: 100%;
  height: 2.60417vw;
  margin-top: 2.60417vw;
  margin-bottom: 2.60417vw;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  border-bottom: 0.5px solid #00C950;
  border-top: 0.5px solid #00C950;
}

.vwa_master_upload_main_container .vwa_upload_label {
  display: -webkit-flex;
  display: flex;
  font-family: 'Roboto', sans-serif;
  font-size: 1.30208vw;
  font-weight: 500;
  height: 2.60417vw;
  -webkit-align-items: center;
          align-items: center;
  color: #FBC02D;
  display: flex;
}

.vwa_master_upload_main_container .vwa_upload_button {
  display: -webkit-flex;
  display: flex;
  font-family: 'Roboto', sans-serif;
  font-size: 1.04167vw;
  font-weight: 500;
  height: 2.60417vw;
  -webkit-align-items: center;
          align-items: center;
  color: #fff;
  background-color: #FBC02D;
  border: solid 0.20833vw #FBC02D;
  padding: 0 0.625vw;
  border-radius: 0.78125vw;
  cursor: pointer;
}

.vwa_master_upload_main_container .vwa_upload_button:active {
  display: -webkit-flex;
  display: flex;
  font-family: 'Roboto', sans-serif;
  font-size: 1.04167vw;
  font-weight: 500;
  height: 2.60417vw;
  -webkit-align-items: center;
          align-items: center;
  color: #fff;
  background-color: #FBC02D;
  border: solid 0.05208vw #FBC02D;
  padding: 0 0.625vw;
  border-radius: 0.78125vw;
  cursor: pointer;
}

.vwa_master_upload_main_container .vwa_upload_button:hover {
  color: #000;
  border: solid 0.15625vw #000;
}

.vwa_master_upload_main_container .vwa_upload_button_turkey {
  display: -webkit-flex;
  display: flex;
  font-family: 'Roboto', sans-serif;
  font-size: 1.04167vw;
  font-weight: 500;
  height: 2.60417vw;
  -webkit-align-items: center;
          align-items: center;
  color: #fff;
  background-color: #FBC02D;
  border: solid 0.20833vw #FBC02D;
  padding: 0.26042vw;
  border-radius: 0.78125vw;
  cursor: pointer;
}

.vwa_master_upload_main_container .vwa_upload_button_turkey:hover {
  color: #000;
  border: solid 0.15625vw #000;
}

.turkey_message_container {
  position: absolute;
  position: absolute;
  display: -webkit-flex;
  display: flex;
  z-index: 99999;
  width: 36vw;
  height: 30vh;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  font-size: 29px;
  background: white;
  box-shadow: 0px 0px 8px 10px #bebebe4f;
  color: black;
  left: 31.25vw;
  border-radius: 15px;
}

.uploading_spinner {
  color: #FBC02D;
  font-size: 10.41667vw;
  z-index: 9999;
  margin-left: 7.8125vw;
}

.modal_lanel_upload {
  display: -webkit-flex;
  display: flex;
  height: 2.60417vw;
  -webkit-align-items: center;
          align-items: center;
  font-family: 'Roboto', sans-serif;
  font-size: 0.9375vw;
  font-weight: 500;
  color: #F44336;
  -webkit-justify-content: center;
          justify-content: center;
}

.modal_input_upload {
  display: -webkit-flex;
  display: flex;
  margin-top: 2.60417vw;
  margin-bottom: 2.60417vw;
  margin-left: auto;
  margin-right: auto;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  font-family: 'Roboto', sans-serif;
  font-size: 0.9375vw;
}

.modal_input_upload input {
  border-bottom: 0.5px solid rgba(0, 0, 0, 0.9);
}

.modal_button_upload {
  display: -webkit-flex;
  display: flex;
  width: 100%;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
}

.modal_upload_spinner {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  font-size: 3vw;
  color: #FBC02D;
}

.modal_upload_success_msg {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  font-size: 1.3vw;
  color: #FBC02D;
}

.single-dpc {
  border: 1px solid;
  margin-top: 1.04167vw;
}

.dpc_single_container {
  padding: 0 0 1.04167vw 0;
}

.color_font1 {
  color: #5a5a5a;
}

.dpc_No {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-align-items: center;
          align-items: center;
  font-size: 1.35417vw;
  margin: 0.52083vw 0;
  font-weight: 700;
  color: #fbc02d;
  letter-spacing: 2px;
}

.dpc_title {
  font-size: 0.9375vw;
  font-weight: 700;
  display: -webkit-flex;
  display: flex;
}

.dpc_title .dpc_title1 {
  -webkit-flex: 2 1;
          flex: 2 1;
  padding-right: 1.04167vw;
}

.dpc_title .dpc_title2 {
  -webkit-flex: 1 1;
          flex: 1 1;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
}

.dpc_padding {
  padding: 0.20833vw 0 0.20833vw 0;
}

.border_b {
  border-bottom: 1px solid #c4c4c4;
}

.border_t {
  border-top: 1px solid #c4c4c4;
}

.border_bb {
  border-bottom: 1px solid #b4b4b4;
}

.border_tt {
  border-top: 1px solid #b4b4b4;
}

.dcp_container {
  display: -webkit-flex;
  display: flex;
  border-bottom: 2px solid #c4c4c4;
}

.dcp_container .dcp_container_left {
  -webkit-flex: 2 1;
          flex: 2 1;
  display: -webkit-flex;
  display: flex;
}

.dcp_container .dcp_container_left .dcp_container_left_left {
  -webkit-flex: 1 1;
          flex: 1 1;
  padding-right: 1.04167vw;
}

.dcp_container .dcp_container_left .dcp_container_left_left .border_b {
  border-bottom: 1px solid #c4c4c4;
}

.dcp_container .dcp_container_left .dcp_container_left_left .inner {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
}

.dcp_container .dcp_container_left .dcp_container_left_right {
  -webkit-flex: 1 1;
          flex: 1 1;
}

.dcp_container .dcp_container_left .dcp_container_left_right .dcp_container_left_right_inner {
  display: -webkit-flex;
  display: flex;
}

.dcp_container .dcp_container_left .dcp_container_left_right .dcp_container_left_right_inner .border_b {
  border-bottom: 1px solid #c4c4c4;
}

.dcp_container .dcp_container_left .dcp_container_left_right .dcp_container_left_right_inner .dcp_container_left_right_inner1 {
  -webkit-flex: 1 1;
          flex: 1 1;
}

.dcp_container .dcp_container_left .dcp_container_left_right .dcp_container_left_right_inner .dcp_container_left_right_inner2 {
  -webkit-flex: 1 1;
          flex: 1 1;
  text-align: right;
  padding-right: 1.04167vw;
}

.dcp_container .dcp_container_right {
  -webkit-flex: 1 1;
          flex: 1 1;
  display: -webkit-flex;
  display: flex;
}

.dcp_container .dcp_container_right .dcp_container_right_left {
  -webkit-flex: 1 1;
          flex: 1 1;
}

.dcp_container .dcp_container_right .dcp_container_right_left div {
  border-bottom: 21x solid #c4c4c4;
}

.dcp_container .dcp_container_right .dcp_container_right_right {
  -webkit-flex: 1 1 1.04167vw;
          flex: 1 1 1.04167vw;
}

.update-data-container .title {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  font-size: 1.35417vw;
  font-weight: 600;
  margin: 1.30208vw;
}

.update-data-container .files input {
  outline: 2px dashed #92b0b3;
  outline-offset: -10px;
  transition: outline-offset 0.15s ease-in-out, background-color 0.15s linear;
  padding: 120px 0px 120px 35%;
  text-align: center !important;
  margin: 0;
  width: 100% !important;
}

.update-data-container .files input:focus {
  outline: 2px dashed #92b0b3;
  outline-offset: -10px;
  transition: outline-offset 0.15s ease-in-out, background-color 0.15s linear;
  border: 1px solid #92b0b3;
}

.update-data-container .files {
  position: relative;
}

.update-data-container .files:after {
  pointer-events: none;
  position: absolute;
  top: 60px;
  left: 0;
  width: 50px;
  right: 0;
  height: 56px;
  content: '';
  display: block;
  margin: 0 auto;
  background-size: 100%;
  background-repeat: no-repeat;
}

.update-data-container .color input {
  background-color: #f1f1f1;
}

.update-data-container .files:before {
  position: absolute;
  bottom: 0px;
  left: 0;
  pointer-events: none;
  width: 100%;
  right: 0;
  height: 57px;
  content: 'or drag and drop.';
  display: block;
  margin: 0 auto;
  color: #2ea591;
  font-weight: 600;
  text-transform: capitalize;
  text-align: center;
}

.update-data-container .sample-list-container {
  text-align: center;
  margin: 2.60417vw 0;
}

.promotion-code-box-outer {
  padding: 1.04167vw;
  border: 0.1px solid;
  width: 30%;
  margin: 10px;
  border: 1px solid #000;
}

.promotion-code-box {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
}

.promotion-codes {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
}

.metal_grade_container {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  width: 100%;
}

.metal_grade_container .metal_grade_label {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  width: 100%;
  height: 2.86458vw;
  -webkit-align-items: center;
          align-items: center;
  font-family: 'Roboto', sans-serif;
  font-size: 1.04167vw;
  font-weight: 700;
  border-bottom: 0.5px solid rgba(0, 0, 0, 0.9);
  color: rgba(0, 0, 0, 0.9);
}

.toggle_btn {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  width: 6.51042vw;
  /* Rounded sliders */
}

.toggle_btn .switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

.toggle_btn .switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.toggle_btn .slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: .4s;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
}

.toggle_btn .slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  transition: .4s;
}

.toggle_btn input:checked + .slider {
  background-color: #2196F3;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
}

.toggle_btn input:focus + .slider {
  box-shadow: 0 0 1px #2196F3;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
}

.toggle_btn input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  transform: translateX(26px);
}

.toggle_btn .slider.round {
  border-radius: 34px;
}

.toggle_btn .slider.round:before {
  border-radius: 50%;
}

.input_container_for_adding_price {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  width: 100%;
  border-bottom: 0.5px solid rgba(0, 0, 0, 0.9);
}

.input_container_for_adding_price .input_class_label {
  display: -webkit-flex;
  display: flex;
  height: 2.86458vw;
  -webkit-align-items: center;
          align-items: center;
  width: 30%;
  font-family: 'Roboto', sans-serif;
  font-size: 0.9375vw;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.9);
}

.input_container_for_adding_price .input_price_input {
  display: -webkit-flex;
  display: flex;
  height: 2.86458vw;
  -webkit-align-items: center;
          align-items: center;
  width: 70%;
}

.metal_grade_row_view {
  display: -webkit-flex;
  display: flex;
  width: 100%;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  border-bottom: 0.5px solid rgba(0, 0, 0, 0.9);
}

.metal_grade_row_view .row_input_view {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  height: 3.90625vw;
  font-family: 'Roboto', sans-serif;
  font-size: 0.9375vw;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.9);
  width: 33%;
  border-left: 0.02604vw solid rgba(0, 0, 0, 0.9);
}

.metal_grade_row_view .row_input_view .row_input_label {
  display: -webkit-flex;
  display: flex;
  font-family: 'Roboto', sans-serif;
  font-size: 0.83333vw;
  height: 1.04167vw;
  -webkit-align-items: center;
          align-items: center;
  padding-left: 0.52083vw;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.9);
}

.metal_grade_row_view .row_input_view .row_input_container {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  padding-left: 0.52083vw;
}

.dpc_inputs {
  display: -webkit-flex;
  display: flex;
  height: 2.60417vw;
  -webkit-align-items: center;
          align-items: center;
  font-family: 'Roboto', sans-serif;
  font-size: 1.04167vw;
  font-weight: 300;
  width: 100%;
  border: none;
  outline: none;
  color: rgba(0, 0, 0, 0.9);
}

.dpc_inputs::-webkit-input-placeholder {
  display: -webkit-flex;
  display: flex;
  height: 2.60417vw;
  -webkit-align-items: center;
          align-items: center;
  font-family: 'Roboto', sans-serif;
  font-size: 1.04167vw;
  font-weight: 300;
  width: 100%;
  border: none;
  outline: none;
  color: rgba(0, 0, 0, 0.9);
}

.dpc_inputs:-ms-input-placeholder {
  display: flex;
  height: 2.60417vw;
  align-items: center;
  font-family: 'Roboto', sans-serif;
  font-size: 1.04167vw;
  font-weight: 300;
  width: 100%;
  border: none;
  outline: none;
  color: rgba(0, 0, 0, 0.9);
}

.dpc_inputs::placeholder {
  display: -webkit-flex;
  display: flex;
  height: 2.60417vw;
  -webkit-align-items: center;
          align-items: center;
  font-family: 'Roboto', sans-serif;
  font-size: 1.04167vw;
  font-weight: 300;
  width: 100%;
  border: none;
  outline: none;
  color: rgba(0, 0, 0, 0.9);
}

.dpc_inputs:focus {
  display: -webkit-flex;
  display: flex;
  height: 2.60417vw;
  -webkit-align-items: center;
          align-items: center;
  font-family: 'Roboto', sans-serif;
  font-size: 1.04167vw;
  font-weight: 300;
  width: 100%;
  border: none;
  outline: none;
  color: rgba(0, 0, 0, 0.9);
}

.dpc_inputs:disabled {
  display: -webkit-flex;
  display: flex;
  height: 2.60417vw;
  -webkit-align-items: center;
          align-items: center;
  font-family: 'Roboto', sans-serif;
  font-size: 1.04167vw;
  font-weight: 300;
  width: 100%;
  border: none;
  outline: none;
  background-color: #aaaaaa;
  color: rgba(0, 0, 0, 0.9);
}

.assesment_type_main_container {
  display: -webkit-flex;
  display: flex;
  width: 100%;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-justify-content: space-between;
          justify-content: space-between;
}

.assesment_type_main_container .assesment_row {
  display: -webkit-flex;
  display: flex;
  width: 49.2%;
  border-bottom: 0.5px solid rgba(0, 0, 0, 0.9);
  -webkit-flex-direction: column;
          flex-direction: column;
  min-height: 20.83333vw;
}

.assesment_type_main_container .assesment_row_inputs {
  display: -webkit-flex;
  display: flex;
  width: 100%;
  border-bottom: 0.5px solid rgba(0, 0, 0, 0.9);
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-justify-content: space-between;
          justify-content: space-between;
}

.assessment_type_label {
  font-family: 'Roboto', sans-serif;
  font-size: 1.04167vw;
  font-weight: 500;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  height: 2.86458vw;
  width: 40%;
  border-bottom: 0.5px solid rgba(0, 0, 0, 0.9);
}

.assesment_type_dropDown {
  display: -webkit-flex;
  display: flex;
  height: 2.86458vw;
  -webkit-align-items: center;
          align-items: center;
  width: 60%;
  border-bottom: 0.5px solid rgba(0, 0, 0, 0.9);
}

.volume_weighted_averge_container {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  width: 100%;
}

.volume_weighted_label {
  font-family: 'Roboto', sans-serif;
  font-size: 1.04167vw;
  font-weight: 500;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  height: 2.86458vw;
  width: 100%;
  border-bottom: 0.5px solid rgba(0, 0, 0, 0.9);
}

.volume_weighted_input {
  display: -webkit-flex;
  display: flex;
  height: 15.625vw;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  color: grey;
  font-family: 'Roboto', sans-serif;
  font-size: 1.30208vw;
  font-weight: 500;
}

.assesment_range_container {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  width: 100%;
}

.assesment_range_label {
  font-family: 'Roboto', sans-serif;
  font-size: 1.04167vw;
  font-weight: 500;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  height: 2.86458vw;
  width: 100%;
  border-bottom: 0.5px solid rgba(0, 0, 0, 0.9);
}

.assesment_input {
  display: -webkit-flex;
  display: flex;
  height: 15.625vw;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  color: grey;
  font-family: 'Roboto', sans-serif;
  font-size: 1.30208vw;
  font-weight: 500;
}

.volume_weighted_index_container {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  width: 100%;
}

.volume_weighted_index_label {
  font-family: 'Roboto', sans-serif;
  font-size: 1.04167vw;
  font-weight: 500;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  height: 2.86458vw;
  width: 100%;
  border-bottom: 0.5px solid rgba(0, 0, 0, 0.9);
}

.volume_weighted_input_container {
  display: -webkit-flex;
  display: flex;
  height: 2.86458vw;
  width: 100%;
  -webkit-flex-direction: row;
          flex-direction: row;
}

.volume_weighted_input_container .label {
  display: -webkit-flex;
  display: flex;
  font-family: 'Roboto', sans-serif;
  font-size: 1.04167vw;
  font-weight: 500;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  height: 2.86458vw;
  width: 40%;
  border-bottom: 0.5px solid rgba(0, 0, 0, 0.9);
}

.volume_weighted_input_container .input {
  display: -webkit-flex;
  display: flex;
  height: 2.86458vw;
  -webkit-align-items: center;
          align-items: center;
  width: 60%;
  border-bottom: 0.5px solid rgba(0, 0, 0, 0.9);
}

.volume_weighted_input_container .input input {
  display: -webkit-flex;
  display: flex;
  height: 2.60417vw;
  -webkit-align-items: center;
          align-items: center;
  font-family: 'Roboto', sans-serif;
  font-size: 1.04167vw;
  font-weight: 300;
  width: 100%;
  border: none;
  outline: none;
}

.volume_weighted_input_container .input input::-webkit-input-placeholder {
  display: -webkit-flex;
  display: flex;
  height: 2.60417vw;
  -webkit-align-items: center;
          align-items: center;
  font-family: 'Roboto', sans-serif;
  font-size: 1.04167vw;
  font-weight: 300;
  width: 100%;
  border: none;
  outline: none;
  color: #c4c4c4;
}

.volume_weighted_input_container .input input:-ms-input-placeholder {
  display: flex;
  height: 2.60417vw;
  align-items: center;
  font-family: 'Roboto', sans-serif;
  font-size: 1.04167vw;
  font-weight: 300;
  width: 100%;
  border: none;
  outline: none;
  color: #c4c4c4;
}

.volume_weighted_input_container .input input::placeholder {
  display: -webkit-flex;
  display: flex;
  height: 2.60417vw;
  -webkit-align-items: center;
          align-items: center;
  font-family: 'Roboto', sans-serif;
  font-size: 1.04167vw;
  font-weight: 300;
  width: 100%;
  border: none;
  outline: none;
  color: #c4c4c4;
}

.volume_weighted_input_container .input input:focus {
  display: -webkit-flex;
  display: flex;
  height: 2.60417vw;
  -webkit-align-items: center;
          align-items: center;
  font-family: 'Roboto', sans-serif;
  font-size: 1.04167vw;
  font-weight: 300;
  width: 100%;
  border: none;
  outline: none;
}

.tradition_index_main_container {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  width: 100%;
}

.tradition_index_main_container .traditional_index_label {
  font-family: 'Roboto', sans-serif;
  font-size: 1.04167vw;
  font-weight: 500;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  height: 2.86458vw;
  width: 100%;
  border-bottom: 0.5px solid rgba(0, 0, 0, 0.9);
}

.traditonal_text_area {
  display: -webkit-flex;
  display: flex;
  widows: 100%;
  height: 13.02083vw;
}

.traditonal_text_area textarea {
  display: -webkit-flex;
  display: flex;
  height: 13.02083vw;
  width: 100%;
  font-family: 'Roboto', sans-serif;
  font-size: 1.04167vw;
  font-weight: 500;
  border: none;
  outline: none;
  resize: none;
}

.calculated_index_input {
  display: -webkit-flex;
  display: flex;
  width: 100%;
  -webkit-flex-direction: column;
          flex-direction: column;
}

.calculated_input_main_container {
  display: -webkit-flex;
  display: flex;
  width: 100%;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  border-bottom: 0.5px solid rgba(0, 0, 0, 0.9);
}

.input_container {
  display: -webkit-flex;
  display: flex;
  width: 80%;
  -webkit-flex-direction: column;
          flex-direction: column;
}

.assesment_button_container {
  display: -webkit-flex;
  display: flex;
  height: 10.41667vw;
  width: 15%;
}

.assesment_button_container {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: space-around;
          justify-content: space-around;
}

.add_buttons {
  display: -webkit-flex;
  display: flex;
  font-family: 'Roboto', sans-serif;
  font-size: 0.83333vw;
  font-weight: 500;
  -webkit-align-items: center;
          align-items: center;
  color: #FBC02D;
  cursor: pointer;
}

.remove_button {
  display: -webkit-flex;
  display: flex;
  height: 5.20833vw;
  width: 5.20833vw;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  background: grey;
}

.view_form_one_main_container {
  display: -webkit-flex;
  display: flex;
  width: 100%;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-justify-content: space-between;
          justify-content: space-between;
}

.view_form_one_main_container .form_part {
  display: -webkit-flex;
  display: flex;
  width: 49.2%;
}

.main_label {
  font-family: 'Roboto', sans-serif;
  font-size: 1.04167vw;
  font-weight: 500;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  height: 2.86458vw;
  border-bottom: 0.5px solid rgba(0, 0, 0, 0.9);
}

.view_input {
  font-family: 'Roboto', sans-serif;
  font-size: 1.04167vw;
  font-weight: 300;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  height: 2.86458vw;
}

.select_publication_main_container {
  display: -webkit-flex;
  display: flex;
  width: 100%;
  -webkit-flex-direction: column;
          flex-direction: column;
}

.publication_view_row_container {
  display: -webkit-flex;
  display: flex;
  height: 2.60417vw;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  border-bottom: 0.5px solid rgba(0, 0, 0, 0.9);
}

.publication_view_row_container .publication_view_Lable {
  display: -webkit-flex;
  display: flex;
  height: 2.60417vw;
  -webkit-align-items: center;
          align-items: center;
  font-family: 'Roboto', sans-serif;
  font-size: 1.04167vw;
  font-weight: 300;
  width: 90%;
}

.publication_view_row_container .publication_view_input {
  display: -webkit-flex;
  display: flex;
  width: 10%;
}

.price_free_container {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  border-bottom: 0.5px solid rgba(0, 0, 0, 0.9);
}

.price_free_container .free_price_label {
  display: -webkit-flex;
  display: flex;
  height: 2.60417vw;
  -webkit-align-items: center;
          align-items: center;
  font-family: 'Roboto', sans-serif;
  font-size: 1.04167vw;
  font-weight: 700;
  width: 80%;
}

.price_free_container .free_price_input {
  display: -webkit-flex;
  display: flex;
  height: 2.60417vw;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: flex-end;
          justify-content: flex-end;
  padding-right: 1.04167vw;
  width: 20%;
}

.add_to_trade_logs_container {
  display: -webkit-flex;
  display: flex;
  width: 100%;
}

.select_sheet_container {
  display: -webkit-flex;
  display: flex;
  width: 100%;
  -webkit-flex-direction: column;
          flex-direction: column;
  min-height: 20.83333vw;
  max-height: 20.83333vw;
  overflow: auto;
  overflow-x: hidden;
  border-bottom: 0.5px solid rgba(0, 0, 0, 0.9);
}

.add_trade_log_container {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  width: 100%;
}

.add_trade_log_container .add_label {
  display: -webkit-flex;
  display: flex;
  width: 80%;
  -webkit-align-items: center;
          align-items: center;
  height: 2.60417vw;
  font-family: 'Roboto', sans-serif;
  font-size: 0.9375vw;
  font-weight: 300;
  border-bottom: 0.5px solid rgba(0, 0, 0, 0.9);
}

.add_trade_log_container .add_input {
  display: -webkit-flex;
  display: flex;
  width: 20%;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  border-bottom: 0.5px solid rgba(0, 0, 0, 0.9);
}

.sheet_label {
  width: 100%;
  display: -webkit-flex;
  display: flex;
  height: 2.86458vw;
  -webkit-align-items: center;
          align-items: center;
  font-family: 'Roboto', sans-serif;
  font-size: 0.9375vw;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.9);
  border-bottom: 0.5px solid rgba(0, 0, 0, 0.9);
}

.informaion_main_container {
  display: -webkit-flex;
  display: flex;
  height: 100%;
  width: 100%;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
}

.info_container {
  display: -webkit-flex;
  display: flex;
  width: 90%;
  margin: auto;
  height: 15.625vw;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  font-family: 'Roboto', sans-serif;
  font-size: 1.04167vw;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.4);
}

.select_sheet_container {
  display: -webkit-flex;
  display: flex;
}

.view_form_main_container_two {
  display: -webkit-flex;
  display: flex;
  width: 100%;
  -webkit-flex-direction: column;
          flex-direction: column;
}

.davis_internal_label {
  display: -webkit-flex;
  display: flex;
  font-family: 'Roboto', sans-serif;
  font-size: 1.30208vw;
  height: 2.60417vw;
  width: 100%;
  -webkit-align-items: center;
          align-items: center;
  font-weight: 500;
  color: #FBC02D;
  cursor: pointer;
  border-bottom: 0.5px solid rgba(0, 0, 0, 0.9);
}

.view_form_label_black {
  display: -webkit-flex;
  display: flex;
  width: 100%;
  height: 2.86458vw;
  -webkit-align-items: center;
          align-items: center;
  font-family: 'Roboto', sans-serif;
  font-size: 1.04167vw;
  font-weight: 700;
  border-bottom: 0.5px solid rgba(0, 0, 0, 0.9);
  color: rgba(0, 0, 0, 0.9);
}

.display_publication {
  display: -webkit-flex;
  display: flex;
  width: 100%;
  height: 2.86458vw;
  -webkit-align-items: center;
          align-items: center;
  font-family: 'Roboto', sans-serif;
  font-size: 0.9375vw;
  font-weight: 300;
  border-bottom: 0.5px solid rgba(0, 0, 0, 0.9);
  color: rgba(0, 0, 0, 0.9);
}

.vwa_prior_values {
  display: -webkit-flex;
  display: flex;
  width: 8.28125vw !important;
  height: 2.23958vw;
  border-left: 0.02604vw solid rgba(0, 0, 0, 0.54);
  border-top: 0.02604vw solid rgba(0, 0, 0, 0.54);
  border-bottom: 0.02604vw solid rgba(0, 0, 0, 0.54);
  font-family: 'Roboto', sans-serif;
  font-size: 0.9375vw;
  font-weight: 300;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
}

.vwa_source_input_single_label {
  display: -webkit-flex;
  display: flex;
  width: 5.20833vw;
  height: 2.23958vw;
  border-left: 0.02604vw solid rgba(0, 0, 0, 0.54);
  border-top: 0.02604vw solid rgba(0, 0, 0, 0.54);
  border-bottom: 0.02604vw solid rgba(0, 0, 0, 0.54);
  font-family: 'Roboto', sans-serif;
  font-size: 0.9375vw;
  font-weight: 300;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  background: #eeeeee;
}

.vwa_source_input_fields {
  display: -webkit-flex;
  display: flex;
  width: 5.20833vw;
  height: 2.23958vw;
  border-left: 0.02604vw solid rgba(0, 0, 0, 0.54);
  border-top: 0.02604vw solid rgba(0, 0, 0, 0.54);
  border-bottom: 0.02604vw solid rgba(0, 0, 0, 0.54);
  font-family: 'Roboto', sans-serif;
  font-size: 0.9375vw;
  font-weight: 300;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
}

.vwa_source_input_fields input[type='number']::-webkit-inner-spin-button,
.vwa_source_input_fields input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.vwa_high_value_details {
  display: -webkit-flex;
  display: flex;
  width: 20%;
  height: 2.60417vw;
  font-family: 'Roboto', sans-serif;
  font-size: 1.30208vw;
  font-weight: 700;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  border-right: 0.02604vw solid black;
}

/******** NEW TRAE LOG CSS ***********/
.display_trade_log_main_container {
  display: -webkit-flex;
  display: flex;
  height: 31.25vw;
  width: 100%;
  overflow: auto;
  overflow-x: hidden;
  -webkit-flex-direction: column;
          flex-direction: column;
  margin-top: 1.5625vw;
  margin-bottom: 1.5625vw;
  border-bottom: 0.02604vw solid rgba(0, 0, 0, 0.9);
}

.full_view {
  min-height: 80vh;
}

.full_view .border_bottom {
  border-bottom: 0.02604vw solid gray;
}

.search_pricesheet {
  display: -webkit-flex;
  display: flex;
  height: 2.91667vw;
  width: 50%;
  border-bottom-left-radius: 0.78125vw;
  border-bottom-right-radius: 0.78125vw;
  background: #00C950;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  font-family: 'Roboto', sans-serif;
  font-size: 1.04167vw;
  font-weight: 500;
  color: white;
  cursor: pointer;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.24), 0px 0px 2px rgba(0, 0, 0, 0.12);
}

.search_pricesheet:hover {
  box-shadow: none;
}

.trade_log_render_main_container {
  display: -webkit-flex;
  display: flex;
  height: 6.25vw;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: center;
          justify-content: center;
  border-top: 0.02604vw solid rgba(0, 0, 0, 0.9);
  margin-top: 0.26042vw;
  margin-bottom: 0.26042vw;
  padding-left: 0.52083vw;
}

.head_line_container {
  display: -webkit-flex;
  display: flex;
  height: 3.125vw;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-justify-content: space-between;
          justify-content: space-between;
}

.head_line_container .trade_log_name_label {
  display: -webkit-flex;
  display: flex;
  height: 3.125vw;
  width: 80%;
  -webkit-align-items: center;
          align-items: center;
  font-family: 'Roboto', sans-serif;
  font-size: 1.5625vw;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.5);
}

.head_line_container .trade_log_manage_button {
  display: -webkit-flex;
  display: flex;
  height: 3.125vw;
  width: 12%;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  font-family: 'Roboto', sans-serif;
  font-size: 1.04167vw;
  background-color: #00C950;
  color: white;
  font-weight: 600;
  border-radius: 0.26042vw;
  letter-spacing: 1.5px;
  box-shadow: 0px 0px 14px -10px rgba(0, 0, 0, 0.75);
  cursor: pointer;
}

.head_line_container .trade_log_manage_button:hover {
  box-shadow: none;
}

.trade_log_info_container {
  display: -webkit-flex;
  display: flex;
  height: 1.04167vw;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  font-family: 'Roboto', sans-serif;
  font-size: 1.04167vw;
  font-weight: 300;
  padding-left: 0.52083vw;
  text-transform: capitalize;
}

.tradelog_search_container {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  margin: 0.41667vw;
}

.pagination_main_display_container {
  display: -webkit-flex;
  display: flex;
  width: 100%;
  height: 2.60417vw;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  margin-top: 2.60417vw;
}

.single_page_number {
  display: inline-block;
  font-family: 'Roboto', sans-serif;
  font-size: 1.5625vw;
  width: 1.04167vw;
  margin-left: 0.52083vw;
  margin-right: 0.52083vw;
  font-weight: 100;
  color: #000;
  cursor: pointer;
}

.single_page_number_active {
  display: inline-block;
  font-family: 'Roboto', sans-serif;
  font-size: 1.5625vw;
  width: 1.04167vw;
  margin-left: 0.52083vw;
  margin-right: 0.52083vw;
  font-weight: 700;
  color: #000;
  cursor: pointer;
}

.upload_main_continer {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  width: 100%;
  height: 23.4375vw;
  margin-top: 2.60417vw;
  margin-bottom: 2.60417vw;
}

.excel_sheet_headline {
  display: -webkit-flex;
  display: flex;
  width: 100%;
  height: 7.8125vw;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  font-family: 'Roboto', sans-serif;
  font-size: 1.5625vw;
  font-weight: 500;
  color: #FBC02D;
}

.uploader_container {
  display: -webkit-flex;
  display: flex;
  height: 5.20833vw;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  width: 100%;
}

.uploader_container input {
  display: -webkit-flex;
  display: flex;
  font-family: 'Roboto', sans-serif;
  font-size: 1.04167vw;
  width: 26.04167vw;
  font-weight: 300;
  outline: none;
  border-bottom: 1px solid rgba(0, 0, 0, 0.54);
}

.publish_button_container {
  display: -webkit-flex;
  display: flex;
  height: 5.20833vw;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  width: 100%;
}

.publish_button_container button {
  width: 15.625vw !important;
}

.trade_log_area_main_containers {
  display: -webkit-flex;
  display: flex;
  margin-top: 1.04167vw;
  -webkit-flex-direction: column;
          flex-direction: column;
  margin-bottom: 2.60417vw;
  width: 100%;
  min-height: 26.04167vw;
  overflow: auto;
  overflow-y: hidden;
}

.no_data {
  display: -webkit-flex;
  display: flex;
  height: 60vh;
}

.no_data h1 {
  margin: auto;
  color: gray;
}

.calculated_section_main_container {
  display: -webkit-flex;
  display: flex;
  height: 20.83333vw;
  width: 20.83333vw;
  -webkit-flex-direction: column;
          flex-direction: column;
  border: 1px solid black;
}

.calculated_section_blank_space {
  display: -webkit-flex;
  display: flex;
  width: 100%;
  height: 6.92708vw;
  border-bottom: 0.02604vw solid rgba(0, 0, 0, 0.54);
  background-color: #80808061;
}

.calculated_section_inputs {
  display: -webkit-flex;
  display: flex;
  width: 100%;
  height: 7.39583vw;
  -webkit-flex-direction: column;
          flex-direction: column;
  border-bottom: 0.02604vw solid rgba(0, 0, 0, 0.54);
}

.calculated_section_comments_main_container {
  display: -webkit-flex;
  display: flex;
  width: 100%;
  height: 6.92708vw;
  -webkit-flex-direction: column;
          flex-direction: column;
  border-bottom: 0.02604vw solid rgba(0, 0, 0, 0.54);
}

.data_section_main_container {
  display: -webkit-flex;
  display: flex;
  height: 20.83333vw;
  width: 20.83333vw;
  -webkit-flex-direction: column;
          flex-direction: column;
  border: 1px solid black;
}

.single_row_trade_log_area {
  display: -webkit-flex;
  display: flex;
  width: 100%;
  -webkit-flex-direction: row;
          flex-direction: row;
  overflow: scroll;
  overflow-y: hidden;
  border: 1px solid;
}

.add_sources_btn {
  display: -webkit-flex;
  display: flex;
  height: 20.83333vw;
  padding: 7.70833vw 8.125vw;
  border: 1px solid;
}

.add_sources_btn i {
  font-size: 5.20833vw;
  color: gray;
  margin: auto;
  cursor: pointer;
}

.data_section_block_section {
  display: -webkit-flex;
  display: flex;
  width: 100%;
  height: 6.92708vw;
  -webkit-flex-direction: column;
          flex-direction: column;
  border-bottom: 0.02604vw solid rgba(0, 0, 0, 0.54);
}

.assign_author_and_publish_data {
  display: -webkit-flex;
  display: flex;
  width: 100%;
  height: 4.16667vw;
  -webkit-align-items: center;
          align-items: center;
}

.assign_author_section {
  display: -webkit-flex;
  display: flex;
  width: 50%;
  height: 4.16667vw;
  -webkit-align-items: center;
          align-items: center;
}

.assign_author_another_tradelog {
  display: -webkit-flex;
  display: flex;
  height: 5.20833vw;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  padding-left: 1.04167vw;
}

.buttons_for_trade_log {
  display: -webkit-flex;
  display: flex;
  width: 100%;
  height: 4.16667vw;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: flex-end;
          justify-content: flex-end;
}

.download_pricesheet_icon {
  color: #00C950;
  margin-right: 2.08333vw;
  font-size: 2.60417vw;
}

.trade_log_name_container {
  display: -webkit-flex;
  display: flex;
  height: 2.60417vw;
  -webkit-align-items: center;
          align-items: center;
  font-family: 'Roboto', sans-serif;
  font-size: 1.04167vw;
  font-weight: 400;
  align-items: center;
  color: #FBC02D;
  border-bottom: 0.5px solid rgba(0, 0, 0, 0.9);
}

.trade_log_name_container .sheet_name_lable {
  font-size: 1.5625vw;
}

.pricesheet_for_review {
  display: -webkit-flex;
  display: flex;
  height: 60vh;
}

.pricesheet_for_review h1 {
  color: #bebebea4;
  margin: auto;
}

.back-to-top {
  position: fixed;
  z-index: 10;
  right: 0;
  bottom: 5%;
  cursor: pointer;
}

.price-title {
  padding: 10px 0 10px 2.08333vw;
}

.trade_logs_container_main {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  width: 100%;
  overflow: auto;
  overflow-y: hidden;
  border-bottom: 0.5px solid rgba(0, 0, 0, 0.9);
  border-top: 0.5px solid rgba(0, 0, 0, 0.9);
  margin-top: 2.60417vw;
  margin-bottom: 2.60417vw;
}

.calculated_block_display {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  height: 20.83333vw;
  width: 20.83333vw;
  border: 0.02604vw solid black;
}

.dpc_blocks_container_main {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
}

.calculated_section_one_bulk_scrap {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  height: 6.92708vw;
  width: 20.83333vw;
  border-bottom: 0.02604vw solid rgba(0, 0, 0, 0.9);
}

.calculated_section_two_bulk_scrap {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  height: 9.01042vw;
  width: 20.83333vw;
  border-bottom: 0.02604vw solid rgba(0, 0, 0, 0.9);
}

.calculated_section_three__bulk_scrap {
  display: -webkit-flex;
  display: flex;
  height: 4.89583vw;
  width: 20.83333vw;
  border-bottom: 0.02604vw solid rgba(0, 0, 0, 0.9);
}

.calculated_dpc_name {
  display: -webkit-flex;
  display: flex;
  height: 2.29167vw;
  width: 100%;
  font-family: 'Roboto', sans-serif;
  font-size: 0.9375vw;
  font-weight: 300;
  padding-left: 0.52083vw;
  -webkit-align-items: center;
          align-items: center;
  background: rgba(0, 0, 0, 0.2);
}

.prior_block_container {
  display: -webkit-flex;
  display: flex;
  height: 2.29167vw;
  width: 100%;
  -webkit-flex-direction: row;
          flex-direction: row;
}

.new_block_container {
  display: -webkit-flex;
  display: flex;
  height: 2.29167vw;
  width: 100%;
  -webkit-flex-direction: row;
          flex-direction: row;
}

.coffiecent_block_container {
  display: -webkit-flex;
  display: flex;
  height: 2.08333vw;
  width: 100%;
  -webkit-flex-direction: row;
          flex-direction: row;
}

.coffiecient_label {
  display: -webkit-flex;
  display: flex;
  height: 2.08333vw;
  width: 8.33333vw;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  font-family: 'Roboto', sans-serif;
  font-size: 0.9375vw;
  font-weight: 700;
  border: 0.02604vw solid rgba(0, 0, 0, 0.9);
}

.cofficient_input {
  display: -webkit-flex;
  display: flex;
  height: 2.08333vw;
  width: 12.5vw;
  -webkit-align-items: center;
          align-items: center;
  border: 0.02604vw solid rgba(0, 0, 0, 0.9);
}

.price_sheet_inputs_coffiecient {
  display: -webkit-flex;
  display: flex;
  width: 97%;
  margin: auto;
  height: 1.92708vw;
  font-family: 'Roboto', sans-serif;
  font-size: 0.9375vw;
  font-weight: 300;
  -webkit-align-items: center;
          align-items: center;
  border: none;
  outline: none;
}

.prior_label {
  display: -webkit-flex;
  display: flex;
  height: 2.29167vw;
  width: 4.16667vw;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  font-family: 'Roboto', sans-serif;
  font-size: 0.9375vw;
  font-weight: 700;
  border: 0.02604vw solid rgba(0, 0, 0, 0.9);
}

.prior_inputs {
  display: -webkit-flex;
  display: flex;
  height: 2.29167vw;
  width: 4.16667vw;
  -webkit-align-items: center;
          align-items: center;
  border: 0.02604vw solid rgba(0, 0, 0, 0.9);
}

.price_sheet_inputs {
  display: -webkit-flex;
  display: flex;
  width: 97%;
  margin: auto;
  height: 2.08333vw;
  font-family: 'Roboto', sans-serif;
  font-size: 0.9375vw;
  font-weight: 300;
  -webkit-align-items: center;
          align-items: center;
  border: none;
  outline: none;
}

.prior_calculate_button {
  display: -webkit-flex;
  display: flex;
  height: 2.29167vw;
  width: 8.17708vw;
  margin: auto;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  color: white;
  background: #00C950;
  font-family: 'Roboto', sans-serif;
  font-size: 0.9375vw;
  font-weight: 500;
}

.price_sheet_text_area {
  display: -webkit-flex;
  display: flex;
  height: 6.875vw;
  width: 20.57292vw;
  margin: auto;
  border: none;
  outline: none;
  resize: none;
  font-family: 'Roboto', sans-serif;
  font-size: 0.9375vw;
  font-weight: 300;
}

.source_company_main_container {
  display: -webkit-flex;
  display: flex;
  height: 2.29167vw;
  width: 100%;
  -webkit-flex-direction: column;
          flex-direction: column;
  border: 0.02604vw solid rgba(0, 0, 0, 0.9);
}

.source_person_main_container {
  display: -webkit-flex;
  display: flex;
  height: 2.29167vw;
  width: 100%;
  -webkit-flex-direction: column;
          flex-direction: column;
  border: 0.02604vw solid rgba(0, 0, 0, 0.9);
}

.enter_type_and_time_container {
  display: -webkit-flex;
  display: flex;
  height: 2.29167vw;
  width: 100%;
  -webkit-flex-direction: row;
          flex-direction: row;
  border: 0.02604vw solid rgba(0, 0, 0, 0.9);
}

.enter_type_and_time_container .enter_type_part {
  display: -webkit-flex;
  display: flex;
  width: 50%;
}

.enter_price_band_container {
  display: -webkit-flex;
  display: flex;
  height: 2.29167vw;
  width: 100%;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-align-items: center;
          align-items: center;
}

.enter_price_band_container .price_bands {
  display: -webkit-flex;
  display: flex;
  width: 100%;
  height: 2.29167vw;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  background: rgba(0, 0, 0, 0.2);
  font-family: 'Roboto', sans-serif;
  font-size: 0.9375vw;
  font-weight: 700;
  border: 0.02604vw solid rgba(0, 0, 0, 0.9);
}

.enter_source_input_container {
  display: -webkit-flex;
  display: flex;
  height: 2.29167vw;
  width: 100%;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-align-items: center;
          align-items: center;
}

.enter_source_input_container .source_price_input {
  display: -webkit-flex;
  display: flex;
  width: 100%;
  height: 2.29167vw;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  background: rgba(0, 0, 0, 0.2);
  font-family: 'Roboto', sans-serif;
  font-size: 0.9375vw;
  font-weight: 700;
  border: 0.02604vw solid rgba(0, 0, 0, 0.9);
}

.assign_to_guest_author {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: flex-start;
          justify-content: flex-start;
  height: 2.60417vw;
  width: 100%;
  font-family: 'Roboto', sans-serif;
  font-size: 0.9375vw;
  font-weight: 500;
  color: #FBC02D;
  border-top: 0.02604vw solid rgba(0, 0, 0, 0.9);
  border-bottom: 0.02604vw solid rgba(0, 0, 0, 0.9);
}

.price_sheet_button {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  width: 100%;
  -webkit-justify-content: flex-end;
          justify-content: flex-end;
  height: 5.20833vw;
  align-items: center;
}

.prior_label_two {
  display: -webkit-flex;
  display: flex;
  height: 2.29167vw;
  width: 4.16667vw;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  font-family: 'Roboto', sans-serif;
  font-size: 0.9375vw;
  font-weight: 700;
  border: 0.02604vw solid rgba(0, 0, 0, 0.9);
}

.prior_inputs_two {
  display: -webkit-flex;
  display: flex;
  height: 2.29167vw;
  width: 5.52083vw;
  -webkit-align-items: center;
          align-items: center;
  border: 0.02604vw solid rgba(0, 0, 0, 0.9);
}

.company_box {
  padding: 20px 10px;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
}

.company_box .company {
  border: 1px solid;
  font-weight: 600;
  padding: 0.26042vw 0.52083vw;
  width: 100%;
}

.company_box .person_box {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
}

.company_box .person {
  width: 23.22917vw;
  border: 1px solid;
}

.company_box .person div {
  border-bottom: 1px solid;
  padding-left: 0.52083vw;
}

.tradlog_details {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  padding: 1.04167vw 0vw;
}

.tradlog_details .name {
  font-size: 2.08333vw;
}

.add_source_btn_container {
  width: 15.625vw;
  border-top: 1px dotted;
  border-bottom: 1px dotted;
  border-right: 1px dotted;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
}

.add_source_btn {
  display: -webkit-flex;
  display: flex;
  width: 9.375vw;
  height: 2.23958vw;
  font-family: 'Roboto', sans-serif;
  font-size: 0.9375vw;
  font-weight: 500;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  background: #00C950;
  color: white;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.15), 0px 0px 1px rgba(0, 0, 0, 0.1);
  cursor: pointer;
}

.name_of_scrap_metals {
  display: -webkit-flex;
  display: flex;
  background: #EEEEEE;
  height: 2.86458vw;
  width: 100%;
  -webkit-align-items: center;
          align-items: center;
  font-family: 'Roboto', sans-serif;
  font-size: 0.9375vw;
  font-weight: 400;
  padding-left: 0.52083vw;
}

.prior_values_and_calculate_main_container {
  display: -webkit-flex;
  display: flex;
  height: 2.29167vw;
  width: 100%;
  -webkit-align-items: center;
          align-items: center;
  -webkit-flex-direction: row;
          flex-direction: row;
}

.prior_label_prior {
  display: -webkit-flex;
  display: flex;
  width: 4.11458vw !important;
  height: 2.23958vw;
  font-family: 'Roboto', sans-serif;
  font-size: 0.9375vw;
  font-weight: 500;
  -webkit-align-items: center;
          align-items: center;
  border-left: 0.02604vw solid rgba(0, 0, 0, 0.54);
  border-bottom: 0.02604vw solid rgba(0, 0, 0, 0.54);
  align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
}

.prior_values {
  display: -webkit-flex;
  display: flex;
  width: 4.6875vw;
  height: 2.23958vw;
  border-left: 0.02604vw solid rgba(0, 0, 0, 0.54);
  border-top: 0.02604vw solid rgba(0, 0, 0, 0.54);
  border-bottom: 0.02604vw solid rgba(0, 0, 0, 0.54);
  font-family: 'Roboto', sans-serif;
  font-size: 0.9375vw;
  font-weight: 300;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
}

.calculate_button {
  display: -webkit-flex;
  display: flex;
  width: 9.375vw;
  height: 2.23958vw;
  font-family: 'Roboto', sans-serif;
  font-size: 0.9375vw;
  font-weight: 500;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  background: #00C950;
  color: white;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.15), 0px 0px 1px rgba(0, 0, 0, 0.1);
  cursor: pointer;
}

.calculate_button:hover {
  box-shadow: none;
}

.dpc_name_and_trae_log_name {
  display: -webkit-flex;
  display: flex;
  height: 2.60417vw;
  font-family: 'Roboto', sans-serif;
  font-size: 1.30208vw;
  font-weight: 700;
  -webkit-align-items: center;
          align-items: center;
  padding-left: 1.04167vw;
}

.calculate_values_container {
  display: -webkit-flex;
  display: flex;
  height: 46.875vw;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  width: 100%;
}

.trade_log_container_main_container {
  display: -webkit-flex;
  display: flex;
  width: 100%;
  height: 36.45833vw !important;
  border: 0.02604vw solid black;
  border-bottom: none;
}

.button_container_main_container {
  display: -webkit-flex;
  display: flex;
  width: 100%;
  height: 5.20833vw !important;
}

.button_container_main_container .button_yellow_ok {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  font-family: 'Roboto', sans-serif;
  font-size: 1.30208vw;
  font-weight: 500;
  color: white;
  border: none;
  background: #FBC02D;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.24), 0px 0px 2px rgba(0, 0, 0, 0.12);
  border-radius: 0.52083vw;
  height: 3.125vw;
  width: 10.41667vw;
}

.trade_log_data_container_trade_part {
  display: -webkit-flex;
  display: flex;
  width: 100%;
  -webkit-flex-direction: column;
          flex-direction: column;
  overflow: auto;
  overflow-x: hidden;
  border: 0.02604vw solid black;
}

.trade_data_body {
  display: -webkit-flex;
  display: flex;
  height: 2.60417vw;
  width: 100%;
  -webkit-flex-direction: row;
          flex-direction: row;
  border-bottom: 0.02604vw solid black;
}

.trade_sno {
  display: -webkit-flex;
  display: flex;
  width: 10%;
  height: 2.60417vw;
  font-family: 'Roboto', sans-serif;
  font-size: 1.30208vw;
  font-weight: 700;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  border-right: 0.02604vw solid black;
}

.trade_sno_details {
  display: -webkit-flex;
  display: flex;
  width: 10%;
  height: 2.60417vw;
  font-family: 'Roboto', sans-serif;
  font-size: 1.30208vw;
  font-weight: 300;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  border-right: 0.02604vw solid black;
}

.trade_log_name {
  display: -webkit-flex;
  display: flex;
  width: 90%;
  height: 2.60417vw;
  font-family: 'Roboto', sans-serif;
  font-size: 1.30208vw;
  font-weight: 700;
  -webkit-align-items: center;
          align-items: center;
  padding-left: 1.04167vw;
}

.source_name_details_head {
  display: -webkit-flex;
  display: flex;
  width: 50%;
  height: 2.60417vw;
  font-family: 'Roboto', sans-serif;
  font-size: 1.30208vw;
  font-weight: 700;
  -webkit-align-items: center;
          align-items: center;
  padding-left: 1.04167vw;
  border-right: 0.02604vw solid black;
}

.high_value_details {
  display: -webkit-flex;
  display: flex;
  width: 10%;
  height: 2.60417vw;
  font-family: 'Roboto', sans-serif;
  font-size: 1.30208vw;
  font-weight: 700;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  border-right: 0.02604vw solid black;
}

.source_name_details_container {
  display: -webkit-flex;
  display: flex;
  width: 50%;
  -webkit-flex-direction: column;
          flex-direction: column;
  border-right: 0.02604vw solid black;
}

.source_companY_name_details {
  display: -webkit-flex;
  display: flex;
  height: 1.30208vw;
  font-family: 'Roboto', sans-serif;
  font-size: 0.9375vw;
  -webkit-align-items: center;
          align-items: center;
  font-weight: 300;
  border-top: 0.02604vw solid black;
}

.trade_log_main_container_comments {
  display: -webkit-flex;
  display: flex;
  width: 35%;
  -webkit-flex-direction: column;
          flex-direction: column;
}

.headlines_note {
  display: -webkit-flex;
  display: flex;
  width: 100%;
  height: 2.60417vw;
  font-family: 'Roboto', sans-serif;
  font-size: 1.30208vw;
  font-weight: 700;
  -webkit-align-items: center;
          align-items: center;
  padding-left: 1.04167vw;
  border-bottom: 0.02604vw solid black;
}

.comments_part_one {
  display: -webkit-flex;
  display: flex;
  height: 16.92708vw;
  width: 100%;
  border-bottom: 0.02604vw solid black;
}

.comments_part_one textarea {
  display: -webkit-flex;
  display: flex;
  height: 16.66667vw;
  width: 98%;
  margin: auto;
  font-family: 'Roboto', sans-serif;
  font-size: 1.04167vw;
  font-weight: 300;
  border: none;
  resize: none;
  outline: none;
}

.button_container_main_container {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  height: 5.20833vw;
  -webkit-justify-content: space-between;
          justify-content: space-between;
}

.new_values_assesment_range_main_container {
  display: -webkit-flex;
  display: flex;
  height: 2.29167vw;
  width: 100%;
  -webkit-align-items: center;
          align-items: center;
  -webkit-flex-direction: row;
          flex-direction: row;
}

.trade_log_input_fields {
  display: -webkit-flex;
  display: flex;
  height: 2.13542vw;
  width: 100%;
  margin: auto;
  border: none;
  outline: none;
  resize: none;
  font-family: 'Roboto', sans-serif;
  font-size: 0.9375vw;
  font-weight: 300;
  border-right: 0.1px solid;
}

.calculated_section_comments_container {
  display: -webkit-flex;
  display: flex;
  height: 2.29167vw;
  width: 100%;
  -webkit-align-items: center;
          align-items: center;
  -webkit-flex-direction: row;
          flex-direction: row;
}

.override_trend_note ::-webkit-input-placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #ff000056;
  opacity: 1;
  /* Firefox */
  font-size: 1.04167vw;
  font-weight: 400;
}

.override_trend_note :-ms-input-placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #ff000056;
  opacity: 1;
  /* Firefox */
  font-size: 1.04167vw;
  font-weight: 400;
}

.override_trend_note ::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #ff000056;
  opacity: 1;
  /* Firefox */
  font-size: 1.04167vw;
  font-weight: 400;
}

.trade_logs_textarea_container {
  display: -webkit-flex;
  display: flex;
  height: 6.77083vw;
  width: 100%;
  margin: auto;
  border: none;
  outline: none;
  resize: none;
  font-family: 'Roboto', sans-serif;
  font-size: 0.9375vw;
  font-weight: 300;
}

.select_source_company_main_container {
  display: -webkit-flex;
  display: flex;
  width: 20.83333vw;
  height: 2.29167vw;
  border-bottom: 0.02604vw solid rgba(0, 0, 0, 0.54);
}

.select_source_company_main_container select {
  background: white;
}

.select_source_company_main_container .delete-source, .select_source_company_main_container .copy-paste {
  width: 2.60417vw;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  border-left: 0.1px solid;
}

.select_source_company_main_container .delete-source i, .select_source_company_main_container .copy-paste i {
  font-size: 1.30208vw;
  cursor: pointer;
}

.select_source_company_main_container .delete-source i:hover, .select_source_company_main_container .copy-paste i:hover {
  font-size: 1.30208vw;
  cursor: pointer;
  color: red;
}

.select_source_company_main_container .copy-paste {
  width: 2.60417vw;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  border-left: 0.1px solid;
}

.select_source_company_main_container .copy-paste i {
  font-size: 1.30208vw;
  cursor: pointer;
}

.select_source_company_main_container .copy-paste i:hover {
  font-size: 1.30208vw;
  cursor: pointer;
  color: #FBC02D;
}

.date-time-today {
  width: 10%;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  cursor: pointer;
  font-size: 1.30208vw;
}

.date-time-today:hover {
  font-size: 1.30208vw;
  cursor: pointer;
  color: #FBC02D;
}

.select_type_and_time_container_main {
  display: -webkit-flex;
  display: flex;
  width: 20.83333vw;
  height: 2.29167vw;
  border-bottom: 0.02604vw solid rgba(0, 0, 0, 0.54);
  -webkit-flex-direction: row;
          flex-direction: row;
}

.select_type_container {
  display: -webkit-flex;
  display: flex;
  width: 30%;
}

.select_type_container select {
  background: white;
}

.select_type_container:first-child {
  border-right: 0.02604vw solid rgba(0, 0, 0, 0.54);
}

.select_time_container {
  width: 65%;
}

.source_input_main_container {
  display: -webkit-flex;
  display: flex;
  width: 20.83333vw;
  height: 4.58333vw;
  -webkit-flex-direction: column;
          flex-direction: column;
}

.source_input_label_section {
  display: -webkit-flex;
  display: flex;
  width: 20.83333vw;
  height: 4.58333vw;
  border-bottom: 0.02604vw solid rgba(0, 0, 0, 0.54);
  -webkit-flex-direction: row;
          flex-direction: row;
}

.source_input_single_label {
  display: -webkit-flex;
  display: flex;
  width: 5.15625vw;
  height: 2.23958vw;
  border: 0.02604vw solid rgba(0, 0, 0, 0.54);
  font-family: 'Roboto', sans-serif;
  font-size: 0.9375vw;
  font-weight: 300;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  background: #eeeeee;
}

.source_input_fields {
  display: -webkit-flex;
  display: flex;
  width: 5.15625vw;
  height: 2.23958vw;
  border: 0.02604vw solid rgba(0, 0, 0, 0.54);
  font-family: 'Roboto', sans-serif;
  font-size: 0.9375vw;
  font-weight: 300;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
}

.source_input_fields input[type='number']::-webkit-inner-spin-button,
.source_input_fields input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.source_price_type_container_main {
  display: -webkit-flex;
  display: flex;
  width: 20.83333vw;
  height: 4.58333vw;
  -webkit-flex-direction: column;
          flex-direction: column;
}

.author_notes_main_container {
  display: -webkit-flex;
  display: flex;
  width: 100%;
  margin-top: 2.60417vw;
  margin-bottom: 0.52083vw;
  padding-top: 0.52083vw;
  padding-bottom: 0.52083vw;
  border-top: 0.02604vw solid black;
  border-bottom: 0.02604vw solid black;
}

.author_notes_class {
  display: -webkit-flex;
  display: flex;
  height: 5.20833vw;
  width: 100%;
  border: none;
  resize: none;
  outline: none;
  font-family: 'Roboto', sans-serif;
  font-size: 0.9375vw;
  font-weight: 300;
}

.author_notes_class::-webkit-input-placeholder {
  font-family: 'Roboto', sans-serif;
  font-size: 1.5625vw;
  font-weight: 700;
  color: grey;
}

.author_notes_class:-ms-input-placeholder {
  font-family: 'Roboto', sans-serif;
  font-size: 1.5625vw;
  font-weight: 700;
  color: grey;
}

.author_notes_class::placeholder {
  font-family: 'Roboto', sans-serif;
  font-size: 1.5625vw;
  font-weight: 700;
  color: grey;
}

.invite_guest_author {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  height: 2.60417vw;
  cursor: pointer;
  font-family: 'Roboto', sans-serif;
  font-size: 0.9375vw;
  color: #00C950;
}

.submit_button_container {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: flex-end;
          justify-content: flex-end;
}

.PriceSheet_Send_back {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  font-family: 'Roboto', sans-serif;
  font-size: 1.30208vw;
  font-weight: 500;
  color: white;
  border: none;
  background: #707070;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.24), 0px 0px 2px rgba(0, 0, 0, 0.12);
  border-radius: 0.52083vw;
  height: 3.125vw;
  width: 10.41667vw;
}

.PriceSheet_Save {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  font-family: 'Roboto', sans-serif;
  font-size: 1.30208vw;
  font-weight: 500;
  color: white;
  border: none;
  background: #FBC02D;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.24), 0px 0px 2px rgba(0, 0, 0, 0.12);
  border-radius: 0.52083vw;
  height: 3.125vw;
  width: 10.41667vw;
}

.assign_next_author_main_container {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  width: 100%;
}

.assign_next_author_headline {
  display: -webkit-flex;
  display: flex;
  height: 2.60417vw;
  font-family: 'Roboto', sans-serif;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  font-size: 1.04167vw;
  font-weight: 500;
  border-bottom: 0.02604vw solid rgba(0, 0, 0, 0.54);
}

.assign_input_container {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  margin-top: 1.04167vw;
  margin-bottom: 1.04167vw;
  width: 90%;
  margin-left: auto;
  margin-right: auto;
  overflow-x: hidden;
  overflow-y: scroll;
  height: 26.04167vw;
}

.assign_input_button_container {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: flex-end;
          justify-content: flex-end;
  width: 100%;
}

.desginer_post_view_area_main_continer {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  margin-top: 2.60417vw;
  margin-bottom: 2.60417vw;
}

.desginer_post_view_area_main_continer .data_section_main_container {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  border: 1px solid #eeeeee;
  width: 100%;
  height: 100%;
}

.desginer_post_view_area_main_continer .add_notice_section_container {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  border: 1px solid #eeeeee;
  width: 34%;
  text-align: center;
}

.view_featured_image_container {
  display: -webkit-flex;
  display: flex;
  height: 28.64583vw;
  width: 100%;
  margin-bottom: 0.52083vw;
}

.view_featured_image_container img {
  height: 28.64583vw;
  width: inherit;
}

.view_headline_main_container {
  display: -webkit-flex;
  display: flex;
  width: 100%;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  border-bottom: 1px solid #000000;
}

.view_headline_main_container .view_headline_text_container {
  display: -webkit-flex;
  display: flex;
  width: 75%;
  min-height: 5.20833vw;
  -webkit-align-items: center;
          align-items: center;
  font-family: 'Roboto', sans-serif;
  font-size: 1.5625vw;
  line-height: 1.875vw;
  color: rgba(0, 0, 0, 0.54);
  font-weight: 700;
  padding: 0.52083vw;
}

.view_headline_main_container .view_date_and_button_container {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  width: 25%;
  height: 5.20833vw;
}

.view_date_container {
  display: -webkit-flex;
  display: flex;
  width: 100%;
  height: 2.60417vw;
  font-family: 'Roboto', sans-serif;
  font-size: 0.9375vw;
  font-weight: 400;
  color: black;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: flex-end;
          justify-content: flex-end;
  padding-right: 0.52083vw;
}

.bookmark_button {
  display: -webkit-flex;
  display: flex;
  background: grey;
  color: white;
  font-family: 'Roboto', sans-serif;
  font-size: 0.9375vw;
  font-weight: 500;
  width: 100%;
  height: 2.60417vw;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
}

.view_summary_main_container {
  margin-top: 0.52083vw;
  margin-bottom: 0.52083vw;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  width: 90%;
  margin-left: auto;
  margin-right: auto;
  min-height: 5.20833vw;
  font-family: 'Roboto', sans-serif;
  font-size: 1.30208vw;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.54);
}

.view_content_main_container {
  display: -webkit-flex;
  display: flex;
  width: 100%;
  margin-top: 0.52083vw;
  margin-bottom: 0.52083vw;
}

.view_content_main_container .ck.ck-editor__main > .ck-editor__editable:not(.ck-focused) {
  border-color: white !important;
  font-family: 'Roboto', sans-serif;
  font-size: 1.04167vw;
  padding: 1.5625vw;
  font-weight: 300;
  width: 100%;
  color: rgba(0, 0, 0, 0.9);
}

.view_content_main_container .ck.ck-toolbar {
  border: none !important;
  font-family: 'Roboto', sans-serif;
  font-size: 1.04167vw;
  color: rgba(0, 0, 0, 0.9);
}

.publication_container {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
}

.publication_container .Publication_name {
  display: -webkit-flex;
  display: flex;
  background-color: #fbc02d;
  color: #fff;
  font-family: 'Roboto', sans-serif;
  font-size: 0.9375vw;
  font-weight: 500;
  width: auto;
  padding: 0vw 1.04167vw;
  height: 3.125vw;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  margin: 0.52083vw 0;
  border-radius: 0.26042vw;
  cursor: pointer;
  margin-right: 0.78125vw;
}

.publication_container .actve_publication {
  display: -webkit-flex;
  display: flex;
  background-color: #fbc02d;
  color: #000;
  font-family: 'Roboto', sans-serif;
  font-size: 0.9375vw;
  font-weight: 500;
  width: auto;
  padding: 0vw 1.04167vw;
  height: 3.125vw;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  margin: 0.52083vw 0;
  border: 2px solid #000;
  border-radius: 0.26042vw;
  cursor: pointer;
  margin-right: 0.78125vw;
}

.send_btn_container {
  margin-top: 1.04167vw;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-align-items: center;
          align-items: center;
  border-bottom: 1px dotted;
}

.pdf_input {
  border: none;
  width: 60%;
}

.pdf_input input {
  width: 100%;
  height: 2.60417vw;
}

.add_newsletter_btn {
  display: -webkit-flex;
  display: flex;
  background-color: #fbc02d;
  color: #fff;
  font-family: 'Roboto', sans-serif;
  font-size: 0.9375vw;
  font-weight: 500;
  width: 10%;
  height: 3.125vw;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  margin: 0.52083vw 0;
  border-radius: 0.26042vw;
  cursor: pointer;
}

.add_newsletter_btn_dis {
  display: -webkit-flex;
  display: flex;
  background-color: #dadada;
  color: #fff;
  font-family: 'Roboto', sans-serif;
  font-size: 0.9375vw;
  font-weight: 500;
  width: 10%;
  height: 3.125vw;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  margin: 0.52083vw 0;
  border-radius: 0.26042vw;
  cursor: pointer;
}

.add_newsletter_btn:hover {
  font-weight: 900;
  border: solid 0.20833vw #000;
}

.add_newsletter_btn:active {
  font-weight: 900;
  border: solid 0.20833vw #FBC02D;
}

.add_newsletter_btn_40 {
  display: -webkit-flex;
  display: flex;
  background-color: #fbc02d;
  color: #fff;
  font-family: 'Roboto', sans-serif;
  font-size: 0.9375vw;
  font-weight: 500;
  width: 40%;
  height: 3.125vw;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  margin: 0.52083vw 0;
  border-radius: 0.26042vw;
  cursor: pointer;
}

.add_newsletter_btn_dis_40 {
  display: -webkit-flex;
  display: flex;
  background-color: #dadada;
  color: #fff;
  font-family: 'Roboto', sans-serif;
  font-size: 0.9375vw;
  font-weight: 500;
  width: 40%;
  height: 3.125vw;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  margin: 0.52083vw 0;
  border-radius: 0.26042vw;
  cursor: pointer;
}

.add_newsletter_btn_40 :hover {
  font-weight: 900;
  border: solid 0.20833vw #000;
}

.add_newsletter_btn_40 :active {
  font-weight: 900;
  border: solid 0.20833vw #FBC02D;
}

.render_display_page_main_container {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  padding: 0.78125vw;
  border-top: 1px solid #c2c2c2;
}

.news_commentary {
  min-height: 15.625vw;
}

.headline_container_render {
  -webkit-flex: 6 1;
          flex: 6 1;
  font-family: 'Roboto', sans-serif;
  font-size: 1.30208vw;
  font-weight: 700;
  color: black;
}

.publish_date_container {
  color: #000;
  font-family: 'Roboto', sans-serif;
  font-size: 0.72917vw;
  font-weight: 300;
}

.date_time_container {
  display: -webkit-flex;
  display: flex;
  font-size: 1.04167vw;
  font-weight: 400;
}

.create_newsletter {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  margin: auto;
  height: 10.41667vw;
}

.create_newsletter h1 {
  color: #e4e4e4;
}

.add_remove_container {
  display: -webkit-flex;
  display: flex;
  -webkit-flex: 2 1;
          flex: 2 1;
  -webkit-justify-content: flex-end;
          justify-content: flex-end;
}

.add_remove_container .add_remove_btn {
  text-align: center;
  font-size: 0.9375vw;
  background-color: #fbc02d;
  border-radius: 0.26042vw;
  padding: 0.3125vw 1.30208vw;
  color: #fff;
  font-weight: 600;
  cursor: pointer;
  border: 2px solid #fbc02d;
}

.add_remove_container button:focus {
  outline: 1px dotted;
  outline: none;
}

.add_remove_container button:active {
  border: 2px solid #ffffff;
}

.card_view {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-align-items: center;
          align-items: center;
  border: 1px solid gray;
  padding: 0.5rem 1rem;
  margin-bottom: 0.5rem;
  background-color: #fff;
  cursor: move;
}

.card_view i {
  color: #d6d6d6;
  cursor: pointer;
  font-size: 20px;
  padding: 5px;
}

.empty_news {
  display: -webkit-flex;
  display: flex;
  height: 10.41667vw;
}

.empty_news h1 {
  margin: auto;
  color: #e4e4e4;
}

.newsletter_heading {
  text-align: center;
  margin-top: 1.5625vw;
  margin-bottom: 0.52083vw;
}

.price-row {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  padding: 0.52083vw 0vw;
}

.price-row .dpc_number, .price-row .parent_category, .price-row .metal, .price-row .location {
  -webkit-flex: 1 1;
          flex: 1 1;
}

.price-row .dpc_website_name {
  -webkit-flex: 4 1;
          flex: 4 1;
}

.price-row .parent_category, .price-row .metal, .price-row .location {
  text-align: center;
}

.price-row:hover {
  background-color: #e6e6e6;
}

.dpc-container {
  display: -webkit-flex;
  display: flex;
  width: 100%;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
}

.dpc-single {
  background-color: #dfdfdf;
  border-radius: 0.52083vw;
  padding: 0.10417vw 0.20833vw;
  margin: 0.26042vw;
}

.add-dpc {
  background-color: #FBC02D;
  padding: 0.15625vw 0.78125vw;
  border-radius: 0.26042vw;
  cursor: pointer;
}

.add-dpc:hover {
  background-color: #FBC02D;
  color: #fff;
  padding: 0.15625vw 0.78125vw;
  border-radius: 0.26042vw;
  cursor: pointer;
}

.pricing-form-container {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  margin-top: 0.52083vw;
}

.pricing-form-container .form-input {
  -webkit-flex: 1 1;
          flex: 1 1;
}

.pricing-form-container .btn-container {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-around;
          justify-content: space-around;
}

.pricing-form-container .create-btn {
  background-color: #FBC02D;
  padding: 0.52083vw 0.52083vw;
  border-radius: 0.26042vw;
  text-align: center;
  cursor: pointer;
}

.SingleGroup-container {
  margin-top: 0.52083vw;
  border: 1px solid gray;
  padding: 0.52083vw;
}

.group-name {
  font-size: 1.14583vw;
  font-weight: 600;
}

.btn-edi-delete {
  margin-top: 0.26042vw;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-around;
          justify-content: space-around;
}

.btn-edi-delete .edit-btn {
  height: 2.08333vw;
  background-color: #2dfb4f94;
  padding: 0.26042vw 0.52083vw;
  border-radius: 0.26042vw;
  text-align: center;
  cursor: pointer;
  min-width: 5.20833vw;
}

.btn-edi-delete .delete-btn {
  background-color: #ff1d1d99;
  padding: 0.26042vw 0.52083vw;
  border-radius: 0.26042vw;
  text-align: center;
  cursor: pointer;
  min-width: 5.20833vw;
}

